import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { connect } from 'react-redux'
import { Grid, Cell } from 'styled-css-grid'
import { Wrapper, Header, Body, Label, Value, Container, CloseButton } from 'src/styles/popover'
import { SEARCH_WRAPPER_CRK } from 'src/constants/basic'

const mapStateToProps = state => ({
  wrapper: state.getIn(['settings', 'wrapper']),
  transportPossibilities: state.getIn(['transportPossibilities', 'results']),
})

const TransportDetailsPopover = ({ status, onClose, wrapper, transportPossibilities, isStore }) => {
  if (!status) {
    return null
  }

  const backdropClickHandler = e => {
    e.preventDefault()
    const target = e.target as HTMLDivElement
    if (target.id === 'backdrop') {
      onClose()
    }
  }

  return (
    <Container id="backdrop" onClick={backdropClickHandler}>
      <Wrapper style={{ minWidth: '200px', maxWidth: '50%' }}>
        <Header>
          Możliwości transportu <CloseButton onClick={onClose}>✕</CloseButton>
        </Header>
        <Body>
          <Grid columns={'1'} gap={'24px'}>
            <Cell>
              <Label>Warunki dostawy</Label>
              <Value>{ReactHtmlParser(wrapper === SEARCH_WRAPPER_CRK ? status : transportPossibilities.getIn([status, 'description3']))}</Value>
            </Cell>
            <Cell>
              <Label>Detale</Label>
              <Value>{ReactHtmlParser(transportPossibilities.getIn([status, wrapper === SEARCH_WRAPPER_CRK ? 'description1' : 'description2']))}</Value>
            </Cell>
          </Grid>
        </Body>
      </Wrapper>
    </Container>
  )
}

export default connect(mapStateToProps)(TransportDetailsPopover)
