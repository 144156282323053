import { AnyAction } from 'redux'
import { put, call } from 'redux-saga/effects'
import * as a from 'src/actions/warningInfo'
import apiCaller from 'src/utils/apiCaller'

export function* fetch(action: AnyAction) {
  yield put(a.warningInfoApiRequest())
  try {
    const { data } = yield call(apiCaller, 'settings/config', '')
    yield put(a.warningInfoApiSuccess(data))
  } catch (err) {
    console.log(err.response)
    yield put(a.warningInfoApiFailure((err.response && err.response.data) || err))
  }
}
