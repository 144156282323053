import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import * as a from 'src/actions/cache'

export default createReducer(
  {
    [a.setMore.toString()]: (state, more) =>
      state
        .mergeIn(['more'], fromJS(more)),
    [a.clearMore.toString()]: (state) =>
      state
        .set('more', fromJS({})),
  },
  fromJS({
    more: {},
  }),
)
