import React from 'react'
import Input from 'react-tagsinput'
import { KEY_ENTER, KEY_COMMA } from 'src/constants/keyCodes'

import { Label, LabelText } from './styles'
import './tagInputStyles.css'

const delimiters = [KEY_ENTER, KEY_COMMA]

const TagsInput: React.FC<any> = ({ label, hasError, placeholder, inputClass, style, ...rest }) => {
  return (
    <Label style={style} hasError={hasError}>
      <LabelText>{label}</LabelText>
      <Input {...rest} addKeys={delimiters} addOnBlur={true} className={inputClass} inputProps={{ placeholder: placeholder }} />
    </Label>
  )
}

export default TagsInput
