import styled from 'styled-components'

export const Wrapper = styled.button<{ withCircle?: boolean }>`
  font-size: 12px;
  height: ${({ withCircle }) => (withCircle ? '22px' : '32px')};
  width: ${({ withCircle }) => (withCircle ? '22px' : 'auto')};
  display: ${({ withCircle }) => (withCircle ? 'inline-flex' : 'flex')};
  appearance: none;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ withCircle, theme }) => (withCircle ? theme.colors.primary : '#fff')};
  border: none;
  border-radius: 4px;
  padding: 0;
  position: relative;
  outline: none;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryHover};
  }

  & + & {
    margin-left: ${({ withCircle }) => (withCircle ? '4px' : '0')};
  }
`

export const IconWrapper = styled.div<{ withCircle?: boolean }>`
  flex: 0 0 ${({ withCircle }) => (withCircle ? '16px' : '22px')};
  width: ${({ withCircle }) => (withCircle ? '16px' : '22px')};
  height: ${({ withCircle }) => (withCircle ? '16px' : '22px')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ withCircle }) => (withCircle ? '#fff' : 'transparent')};
  border-radius: ${({ withCircle }) => (withCircle ? '8px' : '0')};
`

export const LabelText = styled.div`
  padding: 8px;
  flex: 1 1 100%;
  text-align: center;
`
