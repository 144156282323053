import React from 'react'

import { Wrapper, IconWrapper, LabelText } from './styles'

import { ButtonProps } from '.'

const Button: React.FC<ButtonProps> = ({ label, withCircle, icon, ...rest }) => (
  <Wrapper withCircle={withCircle} {...rest}>
    {icon && <IconWrapper withCircle={withCircle}>{icon}</IconWrapper>}
    {label && <LabelText>{label}</LabelText>}
  </Wrapper>
)

export default Button
