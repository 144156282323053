import React from 'react'
import Immutable from 'immutable'
import chunkString from 'src/utils/chunkString'

import { Wrapper, Segment, Separator, Label, Text } from './styles'

export interface BreadcrumbsProps {
  treeCode: string
  categories: Immutable.Map<string, any>
  onClickItem: (treeCode: string, ids: Number[]) => void
}

type PathItem = {
  path: string[]
  isFirst: boolean
}

const renderPath = (treeCode, categories, onClickItem) => {
  const parts = chunkString(treeCode, 3)

  let elPath: string[] = []
  const elPaths: string[][] = []
  const pathsItems: PathItem[] = []

  for (let i = 0; i < parts.length; i++) {
    const element = parts[i]
    elPath = [...elPath, ...(i === 0 ? [] : ['children']), element]
    elPaths.push(elPath)
    pathsItems.push({
      path: elPath,
      isFirst: i === 0,
    })
  }

  return pathsItems.map(({ path, isFirst }) => {
    const name = categories.getIn([...path, 'name'])
    const treeCode = categories.getIn([...path, 'treeCode'])
    const ids = categories.getIn([...path, 'childrenMultiLevelCatIds'])
    return (
      <Segment key={treeCode} onClick={() => onClickItem(treeCode, ids)} style={{ textTransform: isFirst ? 'capitalize' : 'none' }}>
        <Separator> > </Separator>
        <Text>{name}</Text>
      </Segment>
    )
  })
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ treeCode, categories, onClickItem, ...rest }) => {
  return (
    <Wrapper>
      <Label>SZUKASZ W:</Label>
      <Segment className={'first'} onClick={() => onClickItem('', [])}>
        <Text>Wszystko</Text>
      </Segment>
      {treeCode && categories && renderPath(treeCode, categories, onClickItem)}
    </Wrapper>
  )
}

export default Breadcrumbs
