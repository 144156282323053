import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import * as a from 'src/actions/producers'

export default createReducer(
  {
    [a.producersApiRequest.toString()]: state => state.set('loading', true),
    [a.producersApiSuccess.toString()]: (state, results) =>
      state
        .set('results', fromJS(results))
        .set('error', false)
        .set('loading', false),
    [a.producersApiFailure.toString()]: (state, error) => state.set('error', error),
  },
  fromJS({
    loading: false,
    results: [],
    error: false,
  })
)
