import React, { MouseEventHandler } from 'react'
import theme from 'src/theme'

export declare interface CategoryArrowProps {
  children: React.ReactNode
  position: 'right' | 'top' | 'left' | 'bottom'
  targetRect: ClientRect
  popoverRect: ClientRect
  style?: React.CSSProperties
  arrowSize?: number
  arrowColor?: any
  arrowStyle?: React.CSSProperties
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
}

interface ArrowContainerState {
  nudgedLeft: number
  nudgedTop: number
}

const CategoryArrow: React.StatelessComponent<CategoryArrowProps> = ({
  position,
  children,
  style,
  arrowColor = 'red',
  arrowSize = 10,
  arrowStyle,
  popoverRect,
  targetRect,
  onMouseEnter,
  onMouseLeave,
}) => (
  <div
    style={{
      paddingLeft: position === 'right' ? arrowSize : 0,
      paddingTop: position === 'bottom' ? arrowSize : 0,
      paddingBottom: position === 'top' ? arrowSize : 0,
      paddingRight: position === 'left' ? arrowSize : 0,
      ...style,
    }}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div
      style={{
        position: 'absolute',
        transition: 'background-color 0.3s',
        ...((): React.CSSProperties => {
          const arrowWidth = arrowSize * 2

          let top = targetRect.top - popoverRect.top
          let left = targetRect.left - popoverRect.left
          // left = left < 0 ? 0 : left
          // left = left + arrowWidth > popoverRect.width ? popoverRect.width - arrowWidth : left
          // let left = 0
          top = top < 0 ? 0 : top
          top = top + arrowWidth > popoverRect.height ? popoverRect.height - arrowWidth : top
          switch (position) {
            case 'right':
              return {
                borderTop: `${arrowSize}px solid transparent`,
                borderBottom: `${arrowSize}px solid transparent`,
                borderRight: `${arrowSize}px solid ${arrowColor}`,
                left: 0,
                top,
              }
            case 'left':
              return {
                borderTop: `${arrowSize}px solid transparent`,
                borderBottom: `${arrowSize}px solid transparent`,
                borderLeft: `${arrowSize}px solid ${arrowColor}`,
                right: 0,
                top,
              }
            case 'bottom':
              return {
                borderLeft: `1px solid ${theme.colors.formBorder}`,
                borderRight: `1px solid ${theme.colors.formBorder}`,
                backgroundColor: arrowColor,
                width: targetRect.width,
                height: 12,
                top: -1,
                left,
              }
            case 'top':
            default:
              return {
                borderLeft: `${arrowSize}px solid transparent`,
                borderRight: `${arrowSize}px solid transparent`,
                borderTop: `${arrowSize}px solid ${arrowColor}`,
                bottom: 0,
                left,
              }
          }
        })(),
        ...arrowStyle,
      }}
    />
    {children}
  </div>
)

export default CategoryArrow
