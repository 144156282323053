import { BaseHTMLAttributes } from 'react'

import component from './Button'

export interface ButtonProps extends BaseHTMLAttributes<any> {
  label?: string
  icon?: any
  withCircle?: boolean
  dataTip?: any
}

export default component
