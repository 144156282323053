import styled, { keyframes } from 'styled-components'
import { SEARCH_WRAPPER_CRK, SEARCH_WRAPPER_STORE } from 'src/constants'

const getGridColumnsTemplate = (wrapper: string, showPrice: boolean, showBuyPrice: boolean, showCart: boolean) => {
  switch (wrapper) {
    case SEARCH_WRAPPER_CRK:
      return `3fr 1.6fr 110px 60px 60px 50px ${showBuyPrice ? '80px' : ''} ${showPrice ? '80px' : ''} 100px 130px ${showCart ? '130px' : ''}`
    case SEARCH_WRAPPER_STORE:
      return `2fr 1fr 2fr 60px 60px 60px 80px 100px 100px 130px`
  }
}

type RowProps = {
  wrapper: string,
  showCart: boolean
  showPrice: boolean
  showBuyPrice: boolean
}

const RotationAnimation = keyframes`
    from {transform: rotate(0deg)}
    to {transform: rotate(360deg)}
`

export const Table = styled.div`
  width: 100%;
  overflow-x: scroll;
`

export const TableBody = styled.div`
  display: inline-block;
  font-size: 12px;
  overflow: visible;
  min-width: 100%;
`

export const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: ${(props: RowProps) => getGridColumnsTemplate(props.wrapper, props.showPrice, props.showBuyPrice, props.showCart)};
  border-bottom: 1px solid #c5cbcf;
  min-height: 48px;
`

export const HeaderLabel = styled.span`
  font-size: 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 1.18px;
  text-transform: uppercase;
`

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: ${(props: RowProps) => getGridColumnsTemplate(props.wrapper, props.showPrice, props.showBuyPrice, props.showCart)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.formBorder};
  min-height: 48px;
  position: relative;
  background-color: #f0f0f0;

  :nth-child(even) {
    background-color: #f4f4f4;
  }

  :hover {
    background-color: #fff;
    box-shadow: ${({ theme }) => theme.shadows.desaturated};
    z-index: 1;
  }

  &.infoShown {
    background-color: #fff;
    box-shadow: ${({ theme }) => theme.shadows.desaturated};
    z-index: 1;
  }
`

export const TableStoreRow = styled(TableRow)`
  grid-template-columns: 2fr 1fr 1fr 120px 120px 120px 120px 120px 120px;
`

export const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
`

export const SecondaryInfo = styled.div`
  opacity: 0.5;
  font-size: 0.7em;
`

export const LoadingBearing = styled.img`
  animation-duration: 1s;
  animation-name: ${RotationAnimation};
  animation-iteration-count: infinite;
  position: fixed;
  bottom: 48px;
  left: 50%;
  margin-left: -24px;
  display: none;
  width: 96px;
  height: 96px;
  z-index: 10;
  transition: opacity 0.7s;
  opacity: 0;

  &.loading {
    opacity: 1;
    display: block;
  }
`

export const SvgButton = styled.img`
  display: block;
  width: 22px;
  height: 22px;
  cursor: pointer;
`

export const Dimensions = styled.div`
  margin-top: 5px;
`