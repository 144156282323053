import React, { forwardRef } from 'react'

import { Icon, IconClear, Input, Label, LabelText } from './styles'

import { InputTextFieldProps } from '.'

const InputTextField = forwardRef<HTMLInputElement, InputTextFieldProps>(({
                                                                            value,
                                                                            label,
                                                                            hasError,
                                                                            iconSrc,
                                                                            onClear,
                                                                            onClearShow = false,
                                                                            fieldStyle,
                                                                            ...rest
                                                                          }, ref) => (
  <Label style={fieldStyle} hasError={hasError}>
    <LabelText>{label}</LabelText>
    <Input spellCheck={false} hasError={hasError} hasIcon={!!iconSrc} value={value} {...rest} ref={ref}/>
    {iconSrc && <Icon src={iconSrc} alt='icon'/>}
    {onClear && (value || onClearShow) &&
    <IconClear onClick={onClear} src='/icons/x.svg' alt='icon'
               data-tip="kasuj wszystkie filtry"
               data-for="tooltip"/>
    }
  </Label>
))

export default InputTextField
