import React from 'react'

import { Label, LabelText } from '../InputTextField/styles'
import { InputTextFieldProps } from '../InputTextField'

import { Select } from './styles'

export interface InputSelectFieldProps extends InputTextFieldProps {
  items: any[]
}

const InputSelectField: React.FC<InputSelectFieldProps> = ({ label, hasError, items, ...rest }) => (
  <Label hasError={hasError}>
    <LabelText>{label}</LabelText>
    <Select as="select" hasError={hasError} {...rest}>
      {items.map(({ value, name }) => (
        <option value={value} key={`${Math.random().toString(36).substring(7)}-${value}`}>
          {name}
        </option>
      ))}
    </Select>
  </Label>
)

export default InputSelectField
