import styled from 'styled-components'

import { ErrorAwareInput } from '../InputTextField'

import { SelectableItemType } from '.'

export const Wrapper = styled.div<ErrorAwareInput>`
  //border: 1px solid ${({ hasError, theme }) => (hasError ? 'red' : theme.colors.formBorder)};
  color: ${({ hasError, theme }) => (hasError ? 'red' : theme.colors.formLabel)};
  background-color: #f4f4f4;
  border-radius: ${({ theme }) => theme.borderRadius};
  outline: none;
  display: block;
  height: 100%;
`

export const ItemsWrapper = styled.div`
  padding: 12px 4px;
`

export const ItemWithCheckbox = styled.div<SelectableItemType>`
  position: relative;
  color: ${({ selected, theme }) => (selected ? 'black' : theme.colors.formLabel)};
  outline: none;
  display: block;
  width: 100%;
  padding: 0 6px 0 22px;
  text-transform: none;
  font-weight: normal;
  line-height: 20px;

  &:hover {
    background-color: #fafafa;
  }

  &::before {
    content: '';
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.formBorder};
    width: 12px;
    height: 12px;
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 0;
  }

  &::after {
    content: '';
    background-color: ${({ theme, selected }) => (selected ? theme.colors.primary : 'transparent')};
    transition: background-color 0.5s;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 7px;
    left: 7px;
    z-index: 0;
  }
`
export const DefaultLabelWrapper = styled.div<SelectableItemType>`
  display: flex;
  flex-flow: row nowrap;
`

export const DefaultLabelText = styled.div<SelectableItemType>`
  color: ${({ theme, selected }) => (selected ? theme.colors.primary : theme.colors.formLabel)};
  min-width: 10px;
  flex: 1 1 100%;
`

export const DefaultLabelMeta = styled.div<SelectableItemType>`
  color: ${({ theme, selected }) => (selected ? theme.colors.formLabel : theme.colors.formBorder)};
  padding-left: 3px;
  flex: 0 0 15px;
`
