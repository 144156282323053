import { createAction } from 'redux-act'

export const changeFilterValue = createAction('Change Filter Settings', changes => changes)
export const changeAvailability = createAction('Change Availability Settings', availability => availability)
export const changeCategory = createAction('Change Category', (treeCode: string, ids: any) => ({ treeCode, ids }))
export const clearCategory = createAction('Change Category')
export const setCategoryLabels = createAction('Set category labels', (labels: string[]) => labels)
export const setCategoryValues = createAction('Set category values', (values: {}) => values)
export const loadMore = createAction('Load More', (from: number, size: number) => ({ from, size }))

export const showProductInfo = createAction<Number>('Show product info')
export const hideProductInfo = createAction('Hide product info')

export const setEsDescriptions = createAction('Set esDescriptions', descriptions => descriptions)
export const clearEsDescriptions = createAction('Clear esDescriptions')
export const clearDimensions = createAction('Clear dimensions')
export const clearSearchParams = createAction('Clear search params')
export const clearCustomClientFieldsSearchParams = createAction('Clear custom client fields search params')
export const setPreserveEsDescriptions = createAction('Set preserve esDescriptions', (preserve: boolean) => preserve)

export const searchApiRequest = createAction('SEARCH_API_REQUEST')
export const searchApiSuccess = createAction('SEARCH_API_SUCCESS', (data, concatResults: boolean) => ({
  data,
  concatResults,
}))
export const searchApiFailure = createAction<any>('SEARCH_API_FAILURE')

export const resetToDefaultSearchSettings = createAction('Reset to default search settings')

export const changeOptionsValue = createAction('Change search form option', (field: string, value: any) => ({ field, value }))

export const clearGeneralSearchQuery = createAction('Clear general search query')
export const clearDetailedSearchQuery = createAction('Clear detailed search query')
export const clearQueryForAlternativeSearch = createAction('Clear search query for alternative name search')
