import { AnyAction } from 'redux'
import * as a from 'src/actions/producersCatalogs'
import apiCaller from 'src/utils/apiCaller'

import { put, call } from 'redux-saga/effects'

export function* fetch(action: AnyAction) {
  yield put(a.producersCatalogsApiRequest())
  try {
    const { data } = yield call(apiCaller, 'producers-catalogs', '')
    yield put(a.producersCatalogsApiSuccess(data.hits.hits.map(item => item._source)))
  } catch (err) {
    console.log(err.response)
    yield put(a.producersCatalogsApiFailure((err.response && err.response.data) || err))
  }
}
