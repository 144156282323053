import React from 'react'

import { Checkbox, Label, LabelInline, LabelInlineText, LabelText } from './styles'

import { InputCheckboxFieldProps } from '.'

const InputCheckboxField: React.FC<InputCheckboxFieldProps> = ({ label, hasError, inline, style, ...rest }) =>
  inline ? (
    <LabelInline style={style} hasError={hasError}>
      <Checkbox {...rest} type={'checkbox'} />
      <LabelInlineText>{label}</LabelInlineText>
    </LabelInline>
  ) : (
    <Label style={style} hasError={hasError}>
      <LabelText>{label}</LabelText>
      <Checkbox {...rest} type={'checkbox'} />
    </Label>
  )

export default InputCheckboxField
