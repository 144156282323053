import styled from 'styled-components'

type StyledProps = { isActive: boolean; level: number; hasChildren: boolean }

export const Children = styled.div<StyledProps>`
  // margin-left: 2px;
  // transition: background-color 0.3s;
  // border-left: 2px solid transparent;
  // border-left-color: ${({ isActive }) => (isActive ? '#408eba' : 'transparent')};
`
export const Label = styled.div<StyledProps>`
  color: ${({ isActive }) => (isActive ? '#408eba' : 'inherit')};
  font-weight: ${({ isActive, level }) => (isActive ? 'bold' : (level === 2) ? '500' : '300')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 2px;
  transition: background-color 0.3s;

  &:hover {
    color: #408eba;
    font-weight: bold;
  }

  &:hover + ${Children} {
    color: r#408eba;
    font-weight: bold;
  }
`

export const Wrapper = styled.div<StyledProps>`
  display: block;
  padding-left: ${({ level }) => (level - 2) * 8}px;
  font-size: 11px;
  line-height: 18px;
  cursor: pointer;
  margin-top: ${({ level, hasChildren }) => (level === 2 && hasChildren ? 10 : 0)}px;
  max-width: 140px;
  /* margin-bottom: 3px; */

  &:first-child {
    margin-top: 0;
  }
  
  &:hover > ${Children} {
    border-left-color: #408eba;
  }
`
