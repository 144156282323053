import { createReducer } from 'redux-act'
import * as a from 'src/actions/product'
import * as replacementsActions from 'src/actions/replacements'
import * as searchFormActions from 'src/actions/searchForm'
import { fromJS } from 'immutable'

export default createReducer(
  {
    [replacementsActions.hideReplacementsTable.toString()]: state => state.set('results', null),
    [searchFormActions.hideProductInfo.toString()]: state => state.set('results', null),
    [replacementsActions.hideReplacementProductInfo.toString()]: state => state.set('results', null),
    [replacementsActions.hideReplacementProductDetails.toString()]: state => state.set('results', null),
    [a.productApiRequest.toString()]: state => state.set('loading', true),
    [a.productApiSuccess.toString()]: (state, results) =>
      state
        .set('results', fromJS(results))
        .set('error', false)
        .set('loading', false),
    [a.productApiFailure.toString()]: (state, error) =>
      state
        // .set('results', fromJS([]))
        .set('error', error),
  },
  fromJS({
    size: 20,
    from: 0,
  })
)
