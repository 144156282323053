import React from 'react'
import { Grid, Cell } from 'styled-css-grid'
import { Wrapper, Header, Body, Label, Value, Container, CloseButton, Image } from 'src/styles/popover'
import { findCategoryLabelsDimensions } from 'src/utils/categories'
import { DIM_ORDER } from 'src/constants/basic'
import { isImmutable } from 'immutable'
import { getImageUrl } from 'src/utils'

type OwnProps = {
  label?: string
  onClose: () => void
  product: any
  imageLabels: any
  dimLabels: any
  categories: any
  productType: any
  producersCatalogs: any
}

type Props = OwnProps

type ProductDetailsPopoverState = {
  moreOptions: boolean
}

class ProductInfoPopover extends React.PureComponent<Props> {
  backdropClickHandler = e => {
    const { onClose } = this.props
    e.preventDefault()
    const target = e.target as HTMLDivElement
    if (target.id === 'backdrop') {
      onClose()
    }
  }

  render() {
    const { product, onClose, imageLabels, dimLabels, categories, productType, producersCatalogs } = this.props

    if (!product || !isImmutable(product)) return null

    const currentProducersCatalogs = producersCatalogs.filter(pc => pc.get('producer') === product.get('pole1'))
    const labelDimensions = findCategoryLabelsDimensions(product.get('idKategoriiWielopoziomowej'), dimLabels, categories)

    const imagesString = product.get('rysunek')
    const images = imagesString && imagesString.split(';')

    return (
      <Container id="backdrop" onClick={this.backdropClickHandler}>
        <Wrapper>
          <Header>
            Szczegóły artykułu <CloseButton onClick={onClose}>✕</CloseButton>
          </Header>
          <Body>
            <Grid columns={'2'} gap={'24px'}>
              <Cell>
                <Label>Oznaczenie</Label>
                <Value>{product.get('nazwaOryginalna')}</Value>
              </Cell>
              <Cell>
                <Label>Nazwa Orion</Label>
                <Value>{product.get('nazwa')}</Value>
              </Cell>
              <Cell>
                <Label>Producent</Label>
                <Value>{product.get('pole1')}</Value>
              </Cell>
              <Cell width={2}>
                <Label>Opis</Label>
                <Value>{product.get('elasticsearchOpis')}</Value>
                <Value>{product.get('opis')}</Value>
              </Cell>
              {images && images.length && imageLabels.getIn([images[0], 'labels']) ? (
                <>
                  <Cell width={2}>
                    <Label>Rysunek</Label>
                    <Value>
                      <Image src={getImageUrl(images[0])} alt="" />
                    </Value>
                  </Cell>
                  <Cell>
                    {imageLabels.getIn([images[0], 'labels']).map((label, idx) => (
                      <div key={idx}>{label}</div>
                    ))}
                  </Cell>
                  <Cell>
                    {imageLabels.getIn([images[0], 'labels']).map((label, idx) => (
                      <div key={idx}>
                        <span style={{ fontWeight: 'bold' }}>{product.get(DIM_ORDER[idx])}</span>
                      </div>
                    ))}
                  </Cell>
                </>
              ) : (
                <Cell>
                  {(product.get('wymiarW') !== 0 || product.get('wymiarS') !== 0 || product.get('wymiarG') !== 0) && <Label>Wymiary</Label>}
                  <Value>
                    {product.get('wymiarW') !== 0 && (
                      <>
                        {labelDimensions[0]}: {product.get('wymiarW')}
                      </>
                    )}
                    {product.get('wymiarS') !== 0 && (
                      <>
                        &nbsp;/ {labelDimensions[1]}: {product.get('wymiarS')}
                      </>
                    )}
                    {product.get('wymiarG') !== 0 && (
                      <>
                        &nbsp;/ {labelDimensions[2]}: {product.get('wymiarG')}
                      </>
                    )}
                  </Value>
                </Cell>
              )}
              {productType && productType[0] !== 'o' && (
                <Cell width={2}>
                  <p>
                    Uwaga - wybrano równoważnik
                    <br />
                    szukanego produktu, więcej
                    <br />
                    informacji w katalogu
                  </p>
                </Cell>
              )}
              {currentProducersCatalogs.size ? (
                <Cell width={2}>
                  {currentProducersCatalogs.map(pc => (
                    <a
                      key={pc.get('catalog')}
                      href={pc.get('catalog').replace('{nazwa_oryg}', product.get('nazwaOryginalna'))}
                      onClick={() => {
                        window.open(pc.get('catalog').replace('{nazwa_oryg}', product.get('nazwaOryginalna')), '_blank')
                      }}
                    >
                      Katalog producenta
                    </a>
                  ))}
                </Cell>
              ) : null}
            </Grid>
          </Body>
        </Wrapper>
      </Container>
    )
  }
}
export default ProductInfoPopover
