import React from 'react'
import ReactTooltip from 'react-tooltip'
import CategorySelect from 'src/containers/CategorySelect/CategorySelect'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { saveLocation } from 'src/actions/router'
import { hideReplacementsTable } from 'src/actions/replacements'
import {
  changeCategory,
  clearCategory,
  changeFilterValue,
  clearEsDescriptions,
  clearDimensions,
  clearCustomClientFieldsSearchParams,
  changeOptionsValue,
  clearGeneralSearchQuery,
  clearDetailedSearchQuery,
} from 'src/actions/searchForm'
import { producersApiTrigger } from 'src/actions/producers'
import { clearSearchResults } from 'src/actions/searchResults'
import { Cell } from 'styled-css-grid'
import { categoriesApiTrigger } from 'src/actions/categories'
import { producersCatalogsApiTrigger } from 'src/actions/producersCatalogs'
import { transportPossibilitiesApiTrigger } from 'src/actions/transportPossibilities'
import { warningInfoApiTrigger } from 'src/actions/warningInfo'
import { fromJS } from 'immutable'
import Collapse from '@kunukn/react-collapse'
import InputSelectField from 'src/components/InputSelectField'
import InputTextField from 'src/components/InputTextField/InputTextField'
import InputCheckboxField from 'src/components/InputCheckboxField/InputCheckboxField'
import ToggleButton from 'src/components/ToggleButton'
import Button from 'src/components/Button'
import AutoCompleteTagInput from 'src/components/AutoCompleteTagInput'
import TagsInput from 'src/components/TagsInput'
import localStorage from 'src/utils/localStorage'
import { AVAILABILITY_LIST, AVAILABILITY_LIST_STORE, SEARCH_WRAPPER_CRK, SEARCH_WRAPPER_STORE } from 'src/constants'
import { clearMore } from 'src/actions/cache'
import { sendIframeMsg } from 'src/utils'

import {
  Panel,
  LeftColumn,
  Row,
  FlexContainer,
  FlexGrowContent,
  Warning,
  Grid as CustomGrid,
  RightColumn,
  NewSearchButtonWrapper,
  Badges,
  BadgesContainer,
  Wrapper,
} from './styles'

type OwnProps = {
  label?: string
}

const mapStateToProps = (state, ownProps: OwnProps) => ({
  query: state.getIn(['searchForm', 'query']),
  wrapper: state.getIn(['settings', 'wrapper']),
  showSearch: state.getIn(['settings', 'search']),
  categoryLabels: state.getIn(['searchForm', 'categoryLabels']),
  categoryValues: state.getIn(['searchForm', 'categoryValues']),
  categories: state.getIn(['categories', 'results'], fromJS({})),
  aggregatedProducers: state.getIn(['searchResults', 'results', 'aggregations', 'producers', 'buckets'], fromJS([])),
  collapsed: !!state.getIn(['replacements', 'baseProduct']),
  showPrice: state.getIn(['searchForm', 'showPrice']),
  producers: state.getIn(['producers', 'results']),
  showBuyPrice: state.getIn(['searchForm', 'showBuyPrice']),
})

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
  actions: bindActionCreators(
    {
      clearMore,
      saveLocation,
      clearEsDescriptions,
      clearDimensions,
      clearCustomClientFieldsSearchParams,
      clearSearchResults,
      changeCategory,
      clearCategory,
      changeFilterValue,
      categoriesApiTrigger,
      hideReplacementsTable,
      producersCatalogsApiTrigger,
      transportPossibilitiesApiTrigger,
      warningInfoApiTrigger,
      producersApiTrigger,
      changeOptionsValue,
      clearGeneralSearchQuery,
      clearDetailedSearchQuery,
    },
    dispatch,
  ),
})

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type SearchPanelState = {
  moreOptions: boolean
  isFirstRender: boolean
  blockFocusInput: boolean
}

class SearchPanel extends React.PureComponent<Props> {
  handleTagInputChange = field => {
    return tags => {
      const { changeFilterValue } = this.props.actions
      changeFilterValue([{ name: field, value: tags }, { name: 'fullMatch', value: '' }])
    }
  }

  makeChangeHandler = field => {
    const { changeFilterValue, changeOptionsValue, clearMore } = this.props.actions
    return e => {
      clearMore()
      switch (field) {
        case 'showPrice':
        case 'showBuyPrice':
          changeOptionsValue(field, e.target.checked ? '1' : '')
          localStorage.setItem(field, e.target.checked ? '1' : '')
          break
        case 'prefixWildcard':
          changeFilterValue([{ name: field, value: e.target.checked ? '1' : '' }, { name: 'fullMatch', value: '' }])
          break
        case 'onlyOriginal':
          changeFilterValue([{ name: field, value: e.target.checked ? '1' : '' }, { name: 'fullMatch', value: '' }])
          break
        case 'match':
          changeFilterValue([{ name: field, value: typeof e === 'string' ? e : e.target.value }, {
            name: 'fullMatch',
            value: '',
          }])
          break
        case 'availability':
          changeFilterValue([{ name: field, value: typeof e === 'string' ? e : e.target.value }, {
            name: 'fullMatch',
            value: '',
          }])
          break
        default:
          changeFilterValue([{ name: field, value: typeof e === 'string' ? e : e.target.value }, {
            name: 'fullMatch',
            value: '',
          }])
      }
    }
  }

  private onChangeMatch = this.makeChangeHandler('match')
  private onChangeName = this.makeChangeHandler('name')
  private onChangeProducer = this.handleTagInputChange('producerName')
  private onChangeContains = this.handleTagInputChange('contains')
  private onChangeNotContains = this.handleTagInputChange('notContains')
  private onChangeAvailability = this.makeChangeHandler('availability')
  private onChangeDimensionX = this.makeChangeHandler('dimensionX')
  private onChangeDimensionY = this.makeChangeHandler('dimensionY')
  private onChangeDimensionZ = this.makeChangeHandler('dimensionZ')
  private onChangePrefixWildcard = this.makeChangeHandler('prefixWildcard')
  private onChangeOnlyOriginal = this.makeChangeHandler('onlyOriginal')
  private onChangeShowPrice = this.makeChangeHandler('showPrice')
  private onChangeShowBuyPrice = this.makeChangeHandler('showBuyPrice')

  private nameInputRef = React.createRef<HTMLInputElement>()
  private searchInputRef = React.createRef<HTMLInputElement>()

  private blurNameInput = () => {
    if (this.nameInputRef.current) this.nameInputRef.current.blur()
  }
  private focusNameInput = () => {
    if (this.nameInputRef.current) {
      this.nameInputRef.current.focus()
    }
  }
  private blurSearchInput = () => {
    if (this.searchInputRef.current) this.searchInputRef.current.blur()
  }

  private focusSearchInput = () => {
    if (this.searchInputRef.current) {
      this.searchInputRef.current.focus()
    }
  }

  state: SearchPanelState = {
    moreOptions: localStorage.getItem('moreOptions') === '1',
    isFirstRender: true,
    blockFocusInput: false,
  }

  private toggleMoreOptions = () => {
    this.setState((state: SearchPanelState) => {
      localStorage.setItem('moreOptions', !state.moreOptions ? '1' : '0')
      return {
        moreOptions: !state.moreOptions,
      }
    })
  }

  private openMoreOptions = () => {
    this.setState((state: SearchPanelState) => {
      localStorage.setItem('moreOptions', '1')
      return {
        moreOptions: 1,
      }
    })
  }

  private closeMoreOptions = () => {

    this.setState((state: SearchPanelState) => {
      localStorage.setItem('moreOptions', '0')
      return {
        moreOptions: 0,
      }
    })
  }

  private onChangeCategory = (treeCode: string, ids: Number[], tolerance?: string) => {
    this.props.actions.clearEsDescriptions()
    this.props.actions.clearDimensions()
    this.props.actions.clearMore()
    this.props.actions.changeFilterValue([{ name: 'catIds', value: ids }, {
      name: 'tolerance',
      value: tolerance || '',
    }])
    this.props.actions.changeCategory(treeCode, ids)
    this.openMoreOptions()
  }

  private isOptionsActive = () => {
    const { query, showPrice, showBuyPrice } = this.props
    ReactTooltip.rebuild()
    return (
      showPrice === '1' ||
      showBuyPrice === '1' ||
      query.get('prefixWildcard') !== '1' ||
      query.get('onlyOriginal') !== '' ||
      !!query.get('dimensionX') ||
      !!query.get('dimensionY') ||
      !!query.get('dimensionZ') ||
      !!query.get('availability') ||
      !!query.get('name') ||
      !!query.toJS().contains.length ||
      !!query.toJS().notContains.length ||
      !!query.toJS().catIds.length ||
      !!query.toJS().producerName.length
    )
  }

  private clearDescriptionFilter = () => {
    this.props.actions.changeFilterValue([{ name: 'esDescriptions', value: fromJS([]) }, {
      name: 'fullMatch',
      value: '',
    }])
  }

  private parseTypeAndClearDescriptionFilter = (e, field) => {
    // clear description when remove or change more than one letter
    if (!e.target.value || Math.abs(e.target.value.length - this.props.query.get(field).length) !== 1) {
      this.clearDescriptionFilter()
    }
  }

  private clearFilter = () => {
    this.props.actions.clearCategory()
    this.props.actions.clearCustomClientFieldsSearchParams()
    this.props.actions.clearSearchResults()
    this.props.actions.clearEsDescriptions()
  }

  private clearSearchLeavePrices = () => {
    ReactTooltip.rebuild()
    this.clearFilter()
    this.props.actions.clearMore()
    this.props.actions.clearGeneralSearchQuery()
    this.props.actions.clearDimensions()
    this.props.actions.clearSearchResults()
    this.props.actions.clearGeneralSearchQuery()
    this.props.actions.clearDetailedSearchQuery()
    this.props.actions.changeFilterValue([
      { name: 'availability', value: '' },
      { name: 'prefixWildcard', value: '1' },
      { name: 'onlyOriginal', value: '' },
      { name: 'fullMatch', value: '' },
    ])
  }

  private clearSearch() {
    ReactTooltip.rebuild()
    this.clearFilter()
    this.props.actions.clearMore()
    this.props.actions.clearGeneralSearchQuery()
    this.props.actions.clearDimensions()
    this.props.actions.clearSearchResults()
    this.props.actions.clearGeneralSearchQuery()
    this.props.actions.clearDetailedSearchQuery()
    this.props.actions.changeFilterValue([
      { name: 'availability', value: '' },
      { name: 'prefixWildcard', value: '1' },
      { name: 'onlyOriginal', value: '' },
      { name: 'fullMatch', value: '' },
    ])
    this.props.actions.changeOptionsValue('showBuyPrice', '0')
    localStorage.setItem('showBuyPrice', '0')
    this.props.actions.changeOptionsValue('showPrice', '0')
    localStorage.setItem('showPrice', '0')
    this.closeMoreOptions()
  }

  private getAvailabilityList = () => {
    if (this.props.wrapper === SEARCH_WRAPPER_STORE) {
      return AVAILABILITY_LIST_STORE
    }

    return AVAILABILITY_LIST
  }

  handleKeypress(event) {
    if (event.keyCode === 27) {
      sendIframeMsg('CLOSE_POPUP', null)
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeypress, false)
    this.props.actions.producersApiTrigger()
    this.props.actions.categoriesApiTrigger()
    this.props.actions.producersCatalogsApiTrigger()
    this.props.actions.transportPossibilitiesApiTrigger()
    this.props.actions.warningInfoApiTrigger()
    this.setState({
      isFirstRender: false,
    })
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleKeypress)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    const { query, collapsed } = this.props
    const { collapsed: prevCollapsed } = prevProps
    if (query.get('name') && !collapsed && prevCollapsed) {
      this.focusNameInput()
    }
    if (query.get('match') && !collapsed && prevCollapsed) {
      this.focusSearchInput()
    }
    ReactTooltip.rebuild()
  }

  handleFocus = (event) => {
    if (!this.state.blockFocusInput) {
      event.target.select()
    } else {
      this.setState({
        blockFocusInput: false,
      })
    }
  }

  renderForm = () => {
    const { query, categoryLabels, categoryValues, showPrice, showBuyPrice, producers, wrapper } = this.props
    const { collapsed } = this.props
    const { moreOptions } = this.state

    return (
      <FlexContainer>
        <LeftColumn>
          <Row style={{ paddingBottom: '20px' }}>
          <Wrapper>
            {!collapsed || query.get('match') ? (
              <InputTextField
                hasError={false}
                label={'Wyszukiwanie'}
                iconSrc="/icons/i_magnifier.svg"
                placeholder="wpisz czego szukasz, np: 6204 2RS FAG nierdzewne"
                value={query.get('match')}
                onFocus={this.handleFocus}
                onChange={e => {
                  this.parseTypeAndClearDescriptionFilter(e, 'match')
                  this.onChangeMatch(e)
                }}
                onClearShow={this.isOptionsActive()}
                onClear={() => {
                  ReactTooltip.hide()
                  this.clearSearch()
                }}
                ref={this.searchInputRef}
              />
            ) : (
              <InputTextField
                hasError={false}
                label={'Oznaczenie'}
                value={query.get('name')}
                onFocus={this.handleFocus}
                onChange={e => {
                  this.parseTypeAndClearDescriptionFilter(e, 'name')
                  this.onChangeName(e)
                  this.focusNameInput()
                }}
                ref={this.searchInputRef}
                onClear={() => {
                  this.clearSearch()
                }}
              />
            )}
            </Wrapper>
          </Row>
          <Row style={{ paddingBottom: '20px' }}>
            <Cell style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '15px' }}>
              <ToggleButton isExpanded={moreOptions} onClick={this.toggleMoreOptions}
                            label={moreOptions ? 'Mniej filtrów' : 'Więcej filtrów'}>
                {this.isOptionsActive() && <Warning>aktywny</Warning>}
              </ToggleButton>
            </Cell>
            <Cell
              style={{ flex: '1 1 0' }}>
              <CategorySelect
                onChange={(treeCode, ids, tolerance) => {
                  this.onChangeCategory(treeCode, ids, tolerance)
                }}
              />
            </Cell>
          </Row>
          <Row>
            <Collapse isOpen={moreOptions}>
              <Wrapper>
                <CustomGrid>
                  <Cell style={{ gridColumnEnd: 'span 3', gridRowEnd: 'span 1' }}>
                    <Row style={{ display: 'flex', marginBottom: '16px' }}>
                      <InputTextField
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, height: '100%' }}
                        fieldStyle={{ display: 'flex', flexDirection: 'column' }}
                        autoFocus={this.state.isFirstRender}
                        ref={this.nameInputRef}
                        label={'Oznaczenie'}
                        placeholder="np: 6204"
                        value={query.get('name')}
                        onFocus={this.handleFocus}
                        onChange={e => {
                          this.parseTypeAndClearDescriptionFilter(e, 'name')
                          this.onChangeName(e)
                        }}
                      />

                      <TagsInput
                        style={{ display: 'flex', flexDirection: 'column' }}
                        inputClass="react-tagsinput borderRadius0"
                        label={'Zawiera'}
                        placeholder="np: 2RS"
                        value={query.toJS().contains}
                        onChange={this.onChangeContains}
                      />

                      <TagsInput
                        style={{ display: 'flex', flexDirection: 'column' }}
                        inputClass="react-tagsinput borderRadiusR"
                        label={'Nie zawiera'}
                        placeholder="np: C3"
                        value={query.toJS().notContains}
                        onChange={this.onChangeNotContains}
                      />
                    </Row>
                  </Cell>
                  <Cell>
                    <AutoCompleteTagInput
                      label={'Producent'}
                      placeholder={'np: FAG'}
                      suggestionsArray={producers.toJS()}
                      value={query.toJS().producerName}
                      onChange={this.onChangeProducer}
                    />
                  </Cell>
                  <Cell style={{ gridColumnEnd: 'span 2', gridRowStart: 'span 0', gridRowEnd: 'span 2' }}>
                    <BadgesContainer style={{}}>
                      {
                        this.getAvailabilityList().map(({ value, name }, index) => (
                          <Badges style={{ width: index ? '45%' : '100%' }}
                                  className={value === query.get('availability') ? 'active' : ''} key={value}
                                  onClick={() => this.onChangeAvailability(value)}>
                            {name}
                          </Badges>
                        ))
                      }
                    </BadgesContainer>
                  </Cell>
                  <Cell style={{ gridColumnEnd: 'span 4', gridRowStart: '2', gridRowEnd: '2' }}>
                    <Row>
                      <Cell style={{ width: '100%' }}>
                        <CustomGrid style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                          <Cell
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                            }}
                          >
                            {categoryLabels.get('0') &&
                            (categoryValues.get('val0') && categoryValues.get('val0').size ? (
                              <InputSelectField
                                label={categoryLabels.get('0')}
                                placeholder="np: 20, lub od-do"
                                value={query.get('dimensionX')}
                                onChange={this.onChangeDimensionX}
                                items={categoryValues.get('val0').toJS()}
                              />
                            ) : (
                              <InputTextField
                                label={categoryLabels.get('0')}
                                placeholder="np: 20, lub od-do"
                                value={query.get('dimensionX')}
                                onFocus={this.handleFocus}
                                onChange={this.onChangeDimensionX}
                              />
                            ))}
                          </Cell>
                          <Cell
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                            }}
                          >
                            {categoryLabels.get('1') &&
                            (categoryValues.get('val1') && categoryValues.get('val1').size ? (
                              <InputSelectField
                                label={categoryLabels.get('1')}
                                placeholder="np: 47, lub od-do"
                                value={query.get('dimensionY')}
                                onChange={this.onChangeDimensionY}
                                items={categoryValues.get('val1').toJS()}
                              />
                            ) : (
                              <InputTextField
                                label={categoryLabels.get('1')}
                                placeholder="np: 47, lub od-do"
                                value={query.get('dimensionY')}
                                onFocus={this.handleFocus}
                                onChange={this.onChangeDimensionY}
                              />
                            ))}
                          </Cell>
                          <Cell
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                            }}
                          >
                            {categoryLabels.get('2') &&
                            (categoryValues.get('val2') && categoryValues.get('val2').size ? (
                              <InputSelectField
                                label={categoryLabels.get('2')}
                                placeholder="np: 14, lub od-do"
                                value={query.get('dimensionZ')}
                                onChange={this.onChangeDimensionZ}
                                items={categoryValues.get('val2').toJS()}
                              />
                            ) : (
                              <InputTextField
                                label={categoryLabels.get('2')}
                                placeholder="np: 14, lub od-do"
                                value={query.get('dimensionZ')}
                                onFocus={this.handleFocus}
                                onChange={this.onChangeDimensionZ}
                              />
                            ))}
                          </Cell>
                        </CustomGrid>
                        <CustomGrid style={{ gridTemplateColumns: '1fr 1fr' }}>
                          {(wrapper === SEARCH_WRAPPER_CRK) && (
                            <>
                              <Cell
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  paddingTop: '16px',
                                }}
                              >

                                <InputCheckboxField
                                  style={{ marginBottom: '10px' }}
                                  inline
                                  label={'szukaj wewnątrz nazw'}
                                  checked={query.get('prefixWildcard') === '1'}
                                  value={'0'}
                                  onChange={this.onChangePrefixWildcard}
                                />
                                <InputCheckboxField
                                  inline
                                  label={'pokaż tylko oryginały'}
                                  checked={query.get('onlyOriginal') === '1'}
                                  value={'0'}
                                  onChange={this.onChangeOnlyOriginal}
                                />
                              </Cell>
                              <Cell
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  paddingTop: '16px',
                                }}
                              >
                                <InputCheckboxField
                                  inline
                                  label={'pokaż cenę zakupu'}
                                  checked={showBuyPrice === '1'}
                                  value={'0'}
                                  onChange={this.onChangeShowBuyPrice}
                                />
                                <InputCheckboxField
                                  inline
                                  label={'pokaż ceny sprzedaży'}
                                  checked={showPrice === '1'}
                                  value={'0'}
                                  onChange={this.onChangeShowPrice}
                                />
                              </Cell>
                            </>
                          )}
                        </CustomGrid>
                      </Cell>
                    </Row>
                  </Cell>
                </CustomGrid>
              </Wrapper>
            </Collapse>
          </Row>
        </LeftColumn>
      </FlexContainer>
    )
  }

  renderReplacementsForm = () => {

    return (
      <FlexContainer wrap>
        <LeftColumn>
          <InputTextField
            hasError={false}
            iconSrc="/icons/i_magnifier.svg"
            value=""
            placeholder="Nowe wyszukiwanie"
            onFocus={event => {
              this.handleFocus(event)
              this.setState({ blockFocusInput: true })
            }}
            onChange={e => {
              this.clearSearchLeavePrices()
              this.onChangeMatch(e)
              this.focusSearchInput()
            }}
            ref={this.searchInputRef}
            onClear={() => this.clearSearch()}
            style={{ minWidth: '398px' }}
          />
        </LeftColumn>
        <RightColumn>
          <FlexGrowContent style={{ minWidth: '220px' }}>
            <NewSearchButtonWrapper>
              <Button style={{ marginTop: 16, width: '100%', fontSize: '11px', lineHeight: '15px' }}
                      onClick={() => {
                        this.props.actions.hideReplacementsTable()
                        this.onChangeName('')
                        this.focusNameInput()
                        this.openMoreOptions()
                      }} label="Nowe wyszukiwanie, zachowaj filtry"/>
            </NewSearchButtonWrapper>
          </FlexGrowContent>
          <FlexGrowContent style={{ minWidth: '80px' }}>
            <Button
              style={{ marginTop: 16, width: '100%', fontSize: '11px' }}
              onClick={() => {
                this.props.actions.hideReplacementsTable()
                this.blurNameInput()
                this.blurSearchInput()
                this.openMoreOptions()
              }}
              icon={'←'}
              label="Powrót do listy wyników"
            />
          </FlexGrowContent>
        </RightColumn>
      </FlexContainer>
    )
  }

  render() {
    const { collapsed, showSearch } = this.props

    if (!showSearch) {
      return null
    }

    return <Panel>{!collapsed ? this.renderForm() : this.renderReplacementsForm()}</Panel>
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchPanel)
