import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import { showReplacementsTable, hideReplacementsTable } from 'src/actions/replacements'
import { changeAvailability, changeFilterValue, clearQueryForAlternativeSearch } from 'src/actions/searchForm'
import { findCategoryLabelsDimensions } from 'src/utils/categories'
import { DIM_ORDER, SEARCH_WRAPPER_CRK, SEARCH_WRAPPER_STORE } from 'src/constants/basic'
import { AVAILABILITY_LIST, AVAILABILITY_LIST_STORE, SORT_LIST, STATUS_LIST } from 'src/constants'
import { getImageUrl, sendIframeMsg } from 'src/utils'
import SimpleImageSlider from "react-simple-image-slider";
import SliderWrapper from 'src/components/SliderWrapper/SliderWrapper'

import {
  Wrapper,
  InfoRow,
  RowCell,
  Label,
  Value,
  Bold,
  Badges,
  BadgesContainer,
  ProducersContainer,
  FlatRowCell,
  ProducersBadges,
  ProducersBadgesContainer, CollCell,
} from './styles'

// import { TableRow } from 'src/containers/ReplacementsResultsGrid/styles'

type OwnProps = {
  label?: string
}

const mapStateToProps = (state, ownProps: OwnProps) => ({
  wrapper: state.getIn(['settings', 'wrapper']),
  aggregatedProducers: state.getIn(['replacements', 'results', 'aggregations', 'producers', 'buckets'], fromJS([])),
  loading: state.getIn(['replacements', 'loading']),
  baseProduct: state.getIn(['replacements', 'baseProduct']),
  productId: state.getIn(['replacements', 'baseProduct', 'idArtykulu']),
  availability: state.getIn(['searchForm', 'query', 'availability']),
  categories: state.getIn(['categories']),
})

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      showReplacementsTable,
      hideReplacementsTable,
      changeFilterValue,
      changeAvailability,
      clearQueryForAlternativeSearch,
    },
    dispatch,
  ),
})

const ReplacementsBaseProductInfo = ({ loading, baseProduct, aggregatedProducers, actions, availability, productId, categories, wrapper }) => {
  const [producers, setProducers] = useState<any>([])
  const [activeStatuses, setActiveStatuses] = useState<any>([])
  const [activeProducers, setActiveProducers] = useState<any>([])
  const [lastProduct, setLastProduct] = useState<any>(null)
  const [sort, setSort] = useState<any>(null)
  const [avail, setAvail] = useState<any>(null)
  const [dimensions, setDimensions] = useState<any>([])

  useEffect(() => {
    if (!lastProduct || lastProduct !== productId) {
      setProducers(aggregatedProducers.toJS())
      setActiveStatuses([])
      setActiveProducers([])
      // setActiveProducers(aggregatedProducers.toJS().map(producer => producer.key))
      setLastProduct(productId)
      setSort('')
      setAvail(availability)
      setDimensions(getBaseProductDimensions())
    } else {
      let tempProducers = aggregatedProducers.toJS()
      let flatProducers = tempProducers.map(p => p.key)
      let missingProducers = activeProducers.filter(p => !flatProducers.includes(p)).map(p => ({
        key: p,
        doc_count: 0,
      }))

      setProducers([...tempProducers, ...missingProducers])
      // setProducers(aggregatedProducers.toJS())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregatedProducers])

  const findActiveStatus = key => {
    return activeStatuses.find(active => active === key)
  }

  const findActiveProducer = key => {
    return activeProducers.find(active => active === key)
  }

  const chunkArray = (myArray: Array<any>, chunkSize: number) => {
    var results: Array<any> = []

    while (myArray.length) {
      results.push(myArray.splice(0, chunkSize))
    }

    return results
  }

  const onClick = key => {
    let activeBadges = activeProducers
    // badge was active
    if (findActiveProducer(key)) {
      activeBadges = activeProducers.filter(active => active !== key)
      setActiveProducers(activeBadges)
    } else {
      //badge was not active
      activeBadges = [...activeProducers, key]
      setActiveProducers(activeBadges)
    }

    actions.showReplacementsTable(baseProduct, avail, activeBadges.join(','), sort, activeStatuses.join(','))
  }

  const onChangeStatus = statusValue => {
    let activeBadges = activeStatuses
    // badge was active
    if (findActiveStatus(statusValue)) {
      activeBadges = activeStatuses.filter(active => active !== statusValue)
      setActiveStatuses(activeBadges)
    } else {
      //badge was not active
      activeBadges = [...activeStatuses, statusValue]
      setActiveStatuses(activeBadges)
    }

    actions.showReplacementsTable(baseProduct, avail, activeProducers.join(','), sort, activeBadges.join(','))
  }

  const onChangeSort = sortValue => {
    setSort(sortValue)
    actions.showReplacementsTable(baseProduct, avail, activeProducers.join(','), sortValue, activeStatuses.join(','))
  }

  const onChangeAvailability = availabilityValue => {
    setAvail(availabilityValue)
    actions.changeAvailability(availabilityValue)
    actions.showReplacementsTable(baseProduct, availabilityValue, activeProducers.join(','), sort, activeStatuses.join(','))
  }

  const getAvailabilityList = () => {
    console.log(wrapper)
      if (wrapper === SEARCH_WRAPPER_STORE) {
        return AVAILABILITY_LIST_STORE
      }

      return AVAILABILITY_LIST
    }

  const getBaseProductDimensions = () => {
    if (baseProduct) {
      const multiLevelCatId = baseProduct.get('idKategoriiWielopoziomowej')
      const catDimLabels = categories.get('catDimLabels')
      const tempCategories = categories.get('results')

      return findCategoryLabelsDimensions(multiLevelCatId, catDimLabels, tempCategories)
    }

    return []
  }

  const searchByAlternativeName = alternativeName => {
    actions.clearQueryForAlternativeSearch()
    actions.changeFilterValue([{ name: 'name', value: alternativeName }])
  }

  const showImagePopup = (url, current) => {
    sendIframeMsg('IMAGE_POPUP', { images: url, current: current})
  }

  if (!baseProduct) return null

  const imagesString = baseProduct.get('rysunek')
  const images = imagesString && imagesString.split(';')
  const imagesObj = images && images.map(img => ({ url: getImageUrl(img)}))

  return (
    <Wrapper>
      <InfoRow wrapper={wrapper}>
        <CollCell>
          { imagesObj && (
            <SliderWrapper>
              <SimpleImageSlider
                width={'200px'}
                height={'200px'}
                images={imagesObj}
                navStyle={2}
                bgColor={'#fff'}
                showBullets={false}
                onClick={(idx, event) => {showImagePopup(imagesObj, idx)}}
              />
            </SliderWrapper>
          )}
          <RowCell>
            <RowCell>
              <FlatRowCell>
                <Value style={{ fontSize: '22px' }}>
                  {baseProduct.get('elasticsearchOpis')}
                </Value>
              </FlatRowCell>
              <FlatRowCell>
                <Value style={{ fontSize: '22px' }}>
                  {baseProduct.get('nazwaOryginalna')}
                </Value>
              </FlatRowCell>
              <FlatRowCell>
                <Value
                  style={{ fontWeight: 'normal', fontSize: '13px', cursor: 'pointer' }}
                  onClick={() => searchByAlternativeName(baseProduct.get('nazwa'))}
                >
                  {baseProduct.get('nazwa')} (zobacz równoważniki)
                </Value>
              </FlatRowCell>
              <FlatRowCell>
                <Label decoration="lowercase">Producent&ensp;</Label>
                <Value>{baseProduct.get('pole1')}</Value>
              </FlatRowCell>
            </RowCell>
            <RowCell>
              {
                images &&
                images.length &&
                categories.get('imgDimLabels').getIn([images[0], 'labels']) ? (
                  categories
                    .get('imgDimLabels')
                    .getIn([images[0], 'labels'])
                    .map((label, idx, labels) => (
                      baseProduct.get(DIM_ORDER[idx]) !== 0 && (
                        <FlatRowCell key={label}>
                          <Label decoration="none">{label}</Label>
                          <Value leftPadded="10px">{baseProduct.get(DIM_ORDER[idx])} {2 !== idx && <>&emsp;</>}</Value>
                        </FlatRowCell>
                      )
                    ))
                ) : (
                  <Value>
                    {baseProduct.get('wymiarW') !== 0 && (
                      <FlatRowCell>
                        <Label decoration="none">{dimensions[0]}:</Label>
                        <Value leftPadded="10px">{baseProduct.get('wymiarW')}</Value>
                      </FlatRowCell>
                    )}
                    {baseProduct.get('wymiarS') !== 0 && (
                      <FlatRowCell>
                        <Label decoration="none">{dimensions[1]}:</Label>
                        <Value leftPadded="10px">{baseProduct.get('wymiarS')}</Value>
                      </FlatRowCell>
                    )}
                    {baseProduct.get('wymiarG') !== 0 && (
                      <FlatRowCell>
                        <Label decoration="none">{dimensions[2]}:</Label>
                        <Value leftPadded="10px">{baseProduct.get('wymiarG')}</Value>
                      </FlatRowCell>
                    )}
                  </Value>
                )}
            </RowCell>
          </RowCell>
        </CollCell>
        <RowCell>
          <Label decoration="none">Producenci:</Label>
          <ProducersContainer>
              {producers &&
              chunkArray(producers.reduce((list, producer) => {
                let badge = (
                  <ProducersBadges className={findActiveProducer(producer.key) && 'active'} key={producer.key}
                                   onClick={() => onClick(producer.key)}>
                    <Bold>{producer.key}</Bold> ({producer.doc_count})
                  </ProducersBadges>
                )

                producer.key === baseProduct.get('pole1') ? list.unshift(badge) : list.push(badge)

                return list
              }, []), 6).map((value, index) => (
                <ProducersBadgesContainer key={index}>
                  {value}
                </ProducersBadgesContainer>
              ))}
          </ProducersContainer>
        </RowCell>
        <RowCell>
          <Label decoration="none">Dostępność:</Label>
          <BadgesContainer>
            {
              getAvailabilityList().map(({ value, name }) => (
                <Badges className={value === avail ? 'active' : ''} key={value}
                        onClick={() => onChangeAvailability(value)}>
                  {name}
                </Badges>
              ))
            }
          </BadgesContainer>
        </RowCell>
        {wrapper === SEARCH_WRAPPER_CRK && (
          <RowCell>
            <Label decoration="none">Status:</Label>
            <BadgesContainer>
              {
                STATUS_LIST.map(({ value, name }) => (
                  <Badges className={findActiveStatus(value) && 'active'} key={value}
                          onClick={() => onChangeStatus(value)}>
                    {name}
                  </Badges>
                ))
              }
            </BadgesContainer>
          </RowCell>
        )}
        <RowCell>
          <Label>SORTUJ WG:</Label>
          <BadgesContainer>
            {
              SORT_LIST.map(({ value, name }) => (
                <Badges className={value === sort ? 'active' : ''} key={value} onClick={() => onChangeSort(value)}>
                  {name}
                </Badges>
              ))
            }
          </BadgesContainer>
        </RowCell>
      </InfoRow>
    </Wrapper>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReplacementsBaseProductInfo)
