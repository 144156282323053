const PREFIX = 'O_S'

const setItem = (key: string, value: string) => localStorage.setItem(`${PREFIX}_${key}`, value)
const getItem = (key: string) => localStorage.getItem(`${PREFIX}_${key}`)
const clearAll = () => localStorage.clear()

export default {
  setItem,
  getItem,
  clearAll,
}
