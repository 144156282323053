import React from 'react'

import { Wrapper } from './styles'

const SliderWrapper: React.FC = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    {children}
  </Wrapper>
)

export default SliderWrapper
