import { AnyAction } from 'redux'
import { put, call } from 'redux-saga/effects'
import * as a from 'src/actions/transportPossibilities'
import assignKeyToObject from 'src/utils/assignKeyToObject'
import apiCaller from 'src/utils/apiCaller'

export function* fetch(action: AnyAction) {
  yield put(a.transportPossibilitiesApiRequest())
  try {
    const { data } = yield call(apiCaller, 'transport-possibilities', '')
    const parsedData = Object.assign({}, ...data.hits.hits.map(item => assignKeyToObject(item._source, item._source.status)))
    yield put(a.transportPossibilitiesApiSuccess(parsedData))
  } catch (err) {
    console.log(err.response)
    yield put(a.transportPossibilitiesApiFailure((err.response && err.response.data) || err))
  }
}
