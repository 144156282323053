import axios from 'axios'

const API_SERVER_URL = process.env.REACT_APP_API_URL

export default function fetchData(uri: string, queryString: string, header: any = {}) {
  // `axios` function returns promise, you can use any ajax lib, which can
  // return promise, or wrap in promise ajax call
  return axios.get(`${API_SERVER_URL}/${uri}/?${queryString}`, {
    headers: {
      ...header
    }
  }).catch(e => {
    console.log(e)
    if (!e.response) {
      e.response = {
        data: {
          message: e.message,
          status: e.status,
        },
      }
    }
    throw e
  })
}
