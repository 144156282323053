import styled from 'styled-components'

const SIZE = 70
const SPACE = 0

export const Wrapper = styled.div<{ isActive: boolean; isExpanded: boolean }>`
  font-size: 10px;
  background-color: ${({ isActive, isExpanded, theme }) => ((isActive && isExpanded) || isActive ? theme.colors.primary : '#fff')};
  color: ${({ isActive, isExpanded, theme }) => (isActive ? '#fff' : theme.colors.formLabel)};
  box-shadow: ${({ isExpanded, theme }) => (isExpanded ? theme.shadows.desaturated : 'none')};
  position: relative;
  font-weight: bold;
  /* letter-spacing: 0px; */
  display: flex;
  flex-flow: column nowrap;
  margin-right: ${SPACE}px;
  height: 80px;
  width: ${SIZE}px;
  border-radius: ${({ theme }) => theme.borderRadiusBig};
  border-bottom-left-radius: ${({ isExpanded, theme }) => (isExpanded ? '0px' : theme.borderRadiusBig)};
  border-bottom-right-radius: ${({ isExpanded, theme }) => (isExpanded ? '0px' : theme.borderRadiusBig)};
  border: 1px solid ${({ theme }) => theme.colors.formBorder};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, border-radius 0.3s, color 0.3s;

  &:hover {
    /* transition: background-color 0.3s, color 0.3s; */
    color: '#fff';
    /* background-color: ${({ isActive, isExpanded, theme }) => (isActive && isExpanded ? theme.colors.primary : '#fff')}; */
    box-shadow: ${({ theme }) => theme.shadows.desaturated};
  }
  
  &:last-child {
    margin-right: 0;
  }
`

export const CategoryItemImage = styled.img<{ isActive: boolean; isExpanded: boolean }>`
  width: 50px;
  height: 50px;
  display: block;
  filter: ${({ isActive, isExpanded }) => ((isActive && isExpanded) || isActive ? 'grayscale(1) brightness(100)' : 'none')};
`

export const CategoryItemLabel = styled.div`
  padding: 0 3px;
  text-align: center;
  max-width: 250px;
  word-break: break-word;
  text-transform: capitalize;
`

export const CategoryItemCounter = styled.div`
  color: ${({ theme }) => theme.colors.formLabel};
  opacity: 0.4;
  font-size: 9px;
  position: absolute;
  top: 5px;
  right: 5px;
`
