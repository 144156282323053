import { createReducer } from 'redux-act'
import * as a from 'src/actions/searchForm'
import { fromJS } from 'immutable'
import localStorage from 'src/utils/localStorage'

const pageSize = parseInt(process.env.REACT_APP_LAZY_LOAD_PAGE_SIZE || '10', 10)

const DEFAULT_SEARCH_PARAMS = () => ({
  match: '',
  name: '',
  producerName: [],
  contains: [],
  sort: '',
  tolerance: '',
  catIds: [],
  esDescriptions: [],
  availability: localStorage.getItem('availability') === null ? '' : localStorage.getItem('availability'),
  notContains: [],
  dimensionX: '',
  dimensionY: '',
  dimensionZ: '',
  prefixWildcard: localStorage.getItem('prefixWildcard') === null ? '1' : localStorage.getItem('prefixWildcard'),
  onlyOriginal: localStorage.getItem('onlyOriginal') === null ? '' : localStorage.getItem('onlyOriginal'),
  fullMatch: '',
})

const CUSTOM_CLIENT_SEARCH_PARAMS = () => ({
  match: '',
  name: '',
  contains: [],
  sort: '',
  tolerance: '',
  catIds: [],
  esDescriptions: [],
  availability: localStorage.getItem('availability') === null ? '' : localStorage.getItem('availability'),
  notContains: [],
  dimensionX: '',
  dimensionY: '',
  dimensionZ: '',
  prefixWildcard: localStorage.getItem('prefixWildcard') === null ? '1' : localStorage.getItem('prefixWildcard'),
  onlyOriginal: localStorage.getItem('onlyOriginal') === null ? '' : localStorage.getItem('onlyOriginal'),
  fullMatch: '',
})

const DEFAULT_GENERAL_SEARCH_PARAMS = {
  match: '',
}

const DEFAULT_DETAILED_SEARCH_PARAMS = () => ({
  name: '',
  producerName: [],
  contains: [],
  notContains: [],
  dimensionX: '',
  dimensionY: '',
  dimensionZ: '',
  prefixWildcard: localStorage.getItem('prefixWildcard') === null ? '1' : localStorage.getItem('prefixWildcard'),
  onlyOriginal: localStorage.getItem('onlyOriginal') === null ? '' : localStorage.getItem('onlyOriginal'),
  fullMatch: '',
  availability: 'availOrig30days',
})

const ALTERNATIVE_NAME_SEARCH_PARAMS = () => ({
  ...DEFAULT_SEARCH_PARAMS(),
  availability: '',
  fullMatch: '1',
})

const defaultState = () =>
  fromJS({
    categoryLabels: ['Wymiar X', 'Wymiar Y', 'Wymiar Z'],
    categoryValues: {
      val0: [],
      val1: [],
      val2: [],
    },
    query: {
      size: pageSize,
      from: 0,
      ...DEFAULT_SEARCH_PARAMS(),
    },
    showPrice: localStorage.getItem('showPrice') === null ? '0' : localStorage.getItem('showPrice'),
    showBuyPrice: localStorage.getItem('showBuyPrice') === null ? '0' : localStorage.getItem('showBuyPrice'),
  })

export default createReducer(
  {
    [a.changeFilterValue.toString()]: (state: any, changes) => {
      for (const { name, value } of changes) {
        state = state.setIn(['query', name], value)
      }
      return state
    },
    [a.changeAvailability.toString()]: (state: any, availability) => {
      return state.setIn(['query', 'availability'], availability)
    },
    [a.changeCategory.toString()]: (state: any, { treeCode, value }) => {
      return state.setIn(['query', 'esDescriptions'], fromJS([])).setIn(['_oldCategory'], state.get('categoryTreeCode')).setIn(['categoryTreeCode'], treeCode)
    },
    [a.clearCategory.toString()]: (state: any) => {
      return state.setIn(['_oldCategory'], state.get('categoryTreeCode')).setIn(['categoryTreeCode'], '')
    },
    [a.setCategoryLabels.toString()]: (state: any, labels) => {
      return state.set('categoryLabels', labels)
    },
    [a.setCategoryValues.toString()]: (state: any, values) => {
      return state.set('categoryValues', values)
    },
    [a.clearEsDescriptions.toString()]: (state: any) => {
      return state.setIn(['categoryDescriptions'], fromJS([]))
    },
    [a.clearDimensions.toString()]: (state: any) => {
      return state.mergeIn(
        ['query'],
        fromJS({
          dimensionX: '',
          dimensionY: '',
          dimensionZ: '',
        })
      )
    },
    [a.clearSearchParams.toString()]: (state: any) => {
      return state.mergeIn(['query'], fromJS(DEFAULT_SEARCH_PARAMS()))
    },
    [a.clearCustomClientFieldsSearchParams.toString()]: (state: any) => {
      return state.mergeIn(['query'], fromJS(CUSTOM_CLIENT_SEARCH_PARAMS()))
    },
    [a.setEsDescriptions.toString()]: (state: any, descriptions) => {
      return state.setIn(['categoryDescriptions'], fromJS(descriptions))
    },
    [a.setPreserveEsDescriptions.toString()]: (state: any, preserve) => {
      return state.setIn(['preserveEsDescriptions'], preserve)
    },
    [a.changeOptionsValue.toString()]: (state: any, { field, value }) => {
      return state.setIn([field], value)
    },
    [a.resetToDefaultSearchSettings.toString()]: (state: any) => {
      return defaultState()
    },
    [a.clearDetailedSearchQuery.toString()]: (state: any) => {
      return state.mergeIn(['query'], fromJS(DEFAULT_DETAILED_SEARCH_PARAMS()))
    },
    [a.clearGeneralSearchQuery.toString()]: (state: any) => {
      return state.mergeIn(['query'], fromJS(DEFAULT_GENERAL_SEARCH_PARAMS))
    },
    [a.clearQueryForAlternativeSearch.toString()]: (state: any) => {
      return state
        .mergeIn(['query'], fromJS(ALTERNATIVE_NAME_SEARCH_PARAMS()))
        // .set('showPrice', '0')
        // .set('showBuyPrice', '0')
    },
  },
  defaultState()
)
