import { LOCATION_CHANGE } from 'connected-react-router'
import { fromJS } from 'immutable'
import {
  changeFilterValue,
  changeCategory,
  searchApiRequest,
  searchApiSuccess,
  searchApiFailure,
  loadMore,
  setEsDescriptions,
  setPreserveEsDescriptions,
  clearEsDescriptions,
  setCategoryLabels,
  setCategoryValues,
} from 'src/actions/searchForm'
import { producersApiTrigger } from 'src/actions/producers'
import * as categoriesActions from 'src/actions/categories'
import * as replacementsActions from 'src/actions/replacements'
import * as productActions from 'src/actions/product'
import * as producersCatalogsActions from 'src/actions/producersCatalogs'
import * as transportPossibilitiesActions from 'src/actions/transportPossibilities'
import * as warningInfoActions from 'src/actions/warningInfo'
import { AnyAction } from 'redux'
import { clearSearchResults } from 'src/actions/searchResults'
import localStorage from 'src/utils/localStorage'
import apiCaller from 'src/utils/apiCaller'
import chunkString from 'src/utils/chunkString'

import * as routerSagas from './router'
import * as replacementsSagas from './replacements'
import * as productSagas from './product'
import * as producersCatalogs from './producersCatalogs'
import * as transportPossibilities from './transportPossibilities'
import * as warningInfo from './warningInfo'
import * as producersSagas from './producers'

import { takeLatest, put, call, select, delay } from 'redux-saga/effects'

type PathItem = {
  path: string[]
  isFirst: boolean
}

function* getDimLabelsByTreeCode({ payload }: AnyAction) {
  const dimLabels = (yield select()).getIn(['categories', 'catDimLabels'])
  const categories = (yield select()).getIn(['categories', 'results'])

  const parts = chunkString(payload.treeCode, 3)

  let categoryLabels = fromJS(['Wymiar X', 'Wymiar Y', 'Wymiar Z'])
  let categoriesValues = fromJS({
    val0: [],
    val1: [],
    val2: [],
  })

  if (parts) {
    let elPath: string[] = []
    const elPaths: string[][] = []

    for (let i = 0; i < parts.length; i++) {
      const element = parts[i]
      elPath = [...elPath, ...(i === 0 ? [] : ['children']), element]
      elPaths.push(elPath)
    }
    elPaths.reverse()
    const allLabels = elPaths.map(i => dimLabels.get(categories.getIn([...i, 'multiLevelCatId']))).filter(Boolean)
    if (allLabels.length) {
      categoryLabels = allLabels[0].get('dimensions')
      let values = allLabels[0].get('values')
      if (values) {
        categoriesValues = values.reduce((result, v, i) => (
          v && v.size ? result.set(i, v.unshift({
            value: '',
          })) : result.set(i, fromJS([]))
        ), fromJS({}))
      }
    }
  }

  yield put(setCategoryLabels(categoryLabels))
  yield put(setCategoryValues(categoriesValues))
}

function* fetchSearchResults({ type, payload }: AnyAction) {
  yield delay(200)

  const formData = (yield select()).getIn(['searchForm', 'query']).toJS()

  localStorage.setItem('availability', formData.availability)
  localStorage.setItem('prefixWildcard', formData.prefixWildcard)
  localStorage.setItem('onlyOriginal', formData.onlyOriginal)

  let shouldStop = true;

  ['match'].forEach(field => {
    if (formData[field].length > 1 || formData[field].includes('*') || formData[field].includes('?')) shouldStop = shouldStop && false
  });

  ['name', 'producerName', 'contains', 'notContains','dimensionX', 'dimensionY', 'dimensionZ', 'catIds'].forEach(field => {
    if (formData[field].length > 0) shouldStop = shouldStop && false
  });

  if (shouldStop) {
    yield put(clearSearchResults())
    yield put(clearEsDescriptions())
    yield put(setPreserveEsDescriptions(false))
    return
  }

  let preserveEsDescriptions = (yield select()).getIn(['searchForm', 'preserveEsDescriptions'])
  let shouldConcatResults = false
  if (type === loadMore.toString()) {
    formData.from = payload.from
    shouldConcatResults = true
    preserveEsDescriptions = true
  }
  const queryString = Object.keys(formData)
    .filter((key) => {
      // remove empty array from queryString (fix when esDescription is empty string)
      return !(Array.isArray(formData[key]) && formData[key].length === 0)
    })
    .map(key => key + '=' + (Array.isArray(formData[key]) ? formData[key].join('|') : formData[key]))
    .join('&')
  yield put(searchApiRequest())
  yield put(replacementsActions.hideReplacementsTable())

  try {
    const { data } = yield call(apiCaller, 'search', queryString)

    // console.log({ preserveEsDescriptions })
    if (!preserveEsDescriptions) {
      yield put(clearEsDescriptions())
      let esDescription = data.aggregations.esDescription.buckets
      const queryEsDescriptions = formData.esDescriptions

      if (queryEsDescriptions && queryEsDescriptions.length) {
        const emptyEsDescriptions = queryEsDescriptions.reduce((arr, item) => {
          if (!esDescription.some((ele) => ele.key === item)) {
            arr.push({
              key: item,
              doc_count: 0,
            })
          }

          return arr
        }, [])

        esDescription = [...esDescription, ...emptyEsDescriptions]
      }

      yield put(setEsDescriptions(esDescription))
    }
    yield put(setPreserveEsDescriptions(false))

    // console.log('Search results', data.aggregations.products.result.hits.hits)

    yield put(searchApiSuccess(data.aggregations.products.result, shouldConcatResults))
  } catch (err) {
    console.log(err.response)
    yield put(searchApiFailure((err.response && err.response.data) || err))
  }
}

function* fetchCategories(action: AnyAction) {
  yield put(categoriesActions.categoriesApiRequest())
  try {
    const { data } = yield call(apiCaller, 'categories', '')
    yield put(categoriesActions.categoriesApiSuccess(data))
  } catch (err) {
    console.log(err.response)
    yield put(categoriesActions.categoriesApiFailure((err.response && err.response.data) || err))
  }
}

function* fetchCatDimLabels(action: AnyAction) {
  yield put(categoriesActions.catDimLabelsApiRequest())
  try {
    const { data } = yield call(apiCaller, 'labels', '')
    // console.log(data)
    yield put(categoriesActions.catDimLabelsApiSuccess(data))
  } catch (err) {
    console.log(err.response)
    yield put(categoriesActions.catDimLabelsApiFailure((err.response && err.response.data) || err))
  }
}

export default function* saga() {
  yield takeLatest(categoriesActions.categoriesApiTrigger, fetchCategories)
  yield takeLatest(categoriesActions.categoriesApiTrigger, fetchCatDimLabels)
  yield takeLatest(changeCategory, getDimLabelsByTreeCode)
  yield takeLatest(changeFilterValue, fetchSearchResults)
  yield takeLatest(loadMore, fetchSearchResults)
  yield takeLatest(replacementsActions.showReplacementsTable, replacementsSagas.loadReplacementsData)
  yield takeLatest(replacementsActions.showReplacementsTableByProductId, replacementsSagas.loadReplacementsDataByProductId)
  yield takeLatest(productActions.loadProductData, productSagas.loadProductData)
  yield takeLatest(productActions.loadProductDetails, productSagas.loadProductDetails)
  yield takeLatest(productActions.addReplacementToCart, productSagas.addReplacementToCart)
  yield takeLatest(LOCATION_CHANGE, routerSagas.locationChange)
  yield takeLatest(producersCatalogsActions.producersCatalogsApiTrigger, producersCatalogs.fetch)
  yield takeLatest(transportPossibilitiesActions.transportPossibilitiesApiTrigger, transportPossibilities.fetch)
  yield takeLatest(warningInfoActions.warningInfoApiTrigger, warningInfo.fetch)
  yield takeLatest(productActions.addArticleToCart, productSagas.addArticleToCart)
  yield takeLatest(producersApiTrigger, producersSagas.fetch)
}
