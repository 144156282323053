import styled from 'styled-components'

import { ErrorAwareInput } from '.'

export const Checkbox = styled.input<ErrorAwareInput>`
  height: 15px;
  width: 15px;
  margin: 0;
  flex: 0 0 15px;

  &:hover {
    /* box-shadow: ${({ theme }) => theme.defaultHover}; */
  }

  &:active,
  &:focus {
    border-color: ${({ hasError, theme }) => (hasError ? theme.colors.error : theme.colors.primary)};
  }
`
export const Label = styled.label<ErrorAwareInput>`
  display: block;
  width: 100%;
  color: ${({ hasError, theme }) => (hasError ? theme.colors.error : theme.colors.formLabel)};
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  height: 100%;
`

export const LabelInline = styled.label<ErrorAwareInput>`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  color: ${({ hasError, theme }) => (hasError ? theme.colors.error : theme.colors.formLabel)};
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1.1px;
`

export const LabelText = styled.div`
  padding: 0 10px;
  margin-bottom: 7px;
`

export const LabelInlineText = styled.div`
  padding: 0;
  margin-left: 5px;
  font-weight: normal;
`
