import styled, { keyframes } from 'styled-components'
import { desktopSBreakpoint } from 'src/styles/breakpoints'
import { SEARCH_WRAPPER_STORE, SEARCH_WRAPPER_CRK } from 'src/constants/basic'

const availColWidth = 60

const getGridColumnsTemplate = (wrapper: string) => {
  switch (wrapper) {
    case SEARCH_WRAPPER_CRK:
      // name, producer, desc, unit, stock, 2days, 7days, 30days, replacements, toCart, actions
      return `4fr 1.6fr 3fr ${availColWidth * 1.5}px 50px ${availColWidth}px ${availColWidth}px ${availColWidth}px ${availColWidth}px ${availColWidth * 1.5}px 110px 100px`
    case SEARCH_WRAPPER_STORE:
      // name, producer, desc, more, unit, availability, replacements, select
      return `1.65fr .6fr 2.3fr ${availColWidth * 2.5}px ${availColWidth}px 1fr 1fr 1fr`
  }
}

const getGridColumnsMobileTemplate = (wrapper: string) => {
  switch (wrapper) {
    case SEARCH_WRAPPER_CRK:
      // name, producer, desc, unit, stock, 2days, 7days, 30days, replacements, toCart, actions
      return `200px 100px 150px 50px ${availColWidth}px ${availColWidth}px ${availColWidth}px ${availColWidth}px ${availColWidth * 1.5}px 110px 100px`
    case SEARCH_WRAPPER_STORE:
      // name, producer, desc, more, unit, availability, replacements, select
      return `2fr 1fr 2fr ${availColWidth}px ${availColWidth}px 1fr 1fr 1fr`
  }
}

type RowProps = {
  wrapper: string
}

const RotationAnimation = keyframes`
    from {transform: rotate(0deg)}
    to {transform: rotate(360deg)}
`

export const Table = styled.div`
  width: 100%;
  overflow-x: auto;
`

export const TableBody = styled.div`
  display: inline-block;
  font-size: 12px;
  overflow: visible;
  min-width: 100%;
`

export const HeaderRow = styled.div`
  display: grid;
  grid-template-columns: ${(props: RowProps) => getGridColumnsMobileTemplate(props.wrapper)};
  @media (min-width: ${desktopSBreakpoint}) {
    grid-template-columns: ${(props: RowProps) => getGridColumnsTemplate(props.wrapper)};
  }
  border-bottom: 1px solid #c5cbcf;
  min-height: 48px;
`

export const HeaderLabel = styled.span`
  font-size: 10px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 1.18px;
  text-transform: uppercase;
`

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: ${(props: RowProps) => getGridColumnsMobileTemplate(props.wrapper)};
  @media (min-width: ${desktopSBreakpoint}) {
    grid-template-columns: ${(props: RowProps) => getGridColumnsTemplate(props.wrapper)};
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.formBorder};
  min-height: 48px;
  position: relative;
  background-color: #f0f0f0;
  cursor: pointer;

  :nth-child(even) {
    background-color: #f8f8f8;
  }

  :hover {
    background-color: #fff;
    box-shadow: ${({ theme }) => theme.shadows.desaturated};
    z-index: 1;
  }

  &.infoShown {
    background-color: #fff;
    box-shadow: ${({ theme }) => theme.shadows.desaturated};
    z-index: 1;
    font-weight: bold;
  }
`

export const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
`

export const TableCellDesc = styled(TableCell)`
  grid-column: 1/-1;
  grid-row: 2;
  align-items: start;
  overflow: auto;
`

export const ImageSliderWrapper = styled.div`
  display: block;
  width: 200px;
  height: 200px;
  margin: 16px;
`

export const SecondaryInfo = styled.div`
  opacity: 0.5;
  font-size: 0.7em;
`

export const LoadingBearing = styled.img`
  animation-duration: 1s;
  animation-name: ${RotationAnimation};
  animation-iteration-count: infinite;
  position: fixed;
  bottom: 48px;
  left: 50%;
  margin-left: -24px;
  display: none;
  width: 96px;
  height: 96px;
  z-index: 10;
  transition: opacity 0.7s;
  opacity: 0;

  &.loading {
    display: block;
    opacity: 1;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Message = styled.div`
  width: 100%;
  margin: 8px auto;
  font-size: 14px;
  line-height: 1.25;
  @media (min-width: ${desktopSBreakpoint}) {
    width: 50%;
  }
`
export const Dimensions = styled.div`
  margin-top: 5px;
  opacity: 0.5;
  font-size: 0.8em;
`