import styled from 'styled-components'

import { ToggleTextButtonProps } from '.'

export const Wrapper = styled.button<ToggleTextButtonProps>`
  font-size: 12px;
  display: block;
  appearance: none;
  background: none;
  border: none;
  padding: ${({ padding = 0 }) => padding}px;
  position: relative;
  outline: none;
  cursor: pointer;
  font-family: Roboto, sans-serif;

  &:hover {
    /* box-shadow: ${({ theme }) => theme.defaultHover}; */
  }
`

export const Icon = styled.div<{ isExpanded: boolean, padding?: number }>`
  position: absolute;
  width: 6px;
  height: 6px;
  top: ${({ padding = 0 }) => (padding + 4)}px;
  left: ${({ padding = 0 }) => (padding + 3)}px;
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.colors.formLabel};
  border-right: 1px solid ${({ theme }) => theme.colors.formLabel};
  transform: rotate(${({ isExpanded }) => (isExpanded ? '45deg' : '-45deg')});
  transition: transform 0.7s;
`

export const LabelText = styled.div`
  padding: 0 0 0 16px;
  
  &:hover {
    text-decoration: underline;
  }
`
