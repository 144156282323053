// my-theme.ts
import { DefaultTheme } from 'styled-components'

const orionTheme: DefaultTheme = {
  paddingBig: '25px',
  borderRadius: '5px',
  borderRadiusBig: '8px',
  defaultHover: '0 6px 8px 0 rgba(64, 142, 186, 0.15)',
  shadows: {
    desaturated: '0 6px 8px -3px rgba(166, 181, 189, 0.46)',
    big: '0 6px 8px 0 rgba(64, 142, 186, 0.15)',
    small: '0 6px 8px 0 rgba(64, 142, 186, 0.15)',
  },
  colors: {
    primary: '#408eba',
    primaryHover: '#63a3c9',
    secondary: 'magenta',
    formBorder: '#e2e2e2',
    formLabel: '#404040',
    background: '#f0f0f0',
    error: 'red',
  },
}

export default orionTheme
