import { AnyAction } from 'redux'
import * as productActions from 'src/actions/product'
import apiCaller from 'src/utils/apiCaller'
import sendIframeMsg from 'src/utils/iframe'

import { put, call } from 'redux-saga/effects'

const getData = p => o => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)

const getNestData = getData(['aggregations', 'products', 'result', 'hits', 'hits', '0', '_source'])

export function* loadProductData(action: AnyAction) {
  yield put(productActions.productApiRequest(action.payload))
  try {
    const { data } = yield call(apiCaller, 'search', `id=${action.payload}&from=0&size=1&match=`)
    yield put(productActions.productApiSuccess(getNestData(data)))
  } catch (err) {
    console.log(err.response)
    yield put(productActions.productApiFailure((err.response && err.response.data) || err))
  }
}

export function* loadProductDetails(action: AnyAction) {
  yield put(productActions.productApiRequest(action.payload))
  try {
    const { data } = yield call(apiCaller, `products/${action.payload}`, '')
    yield put(productActions.productApiSuccess(data))
  } catch (err) {
    console.log(err.response)
    yield put(productActions.productApiFailure((err.response && err.response.data) || err))
  }
}

export function* addReplacementToCart({ payload }: ReturnType<typeof productActions.addReplacementToCart>) {
  const { data: artData1 } = yield call(apiCaller, 'search', `id=${payload.replacementItem.get('productId')}&from=0&size=1&match=`)
  const { data: artData2 } = yield call(apiCaller, 'search', `id=${payload.replacementItem.get('describedArticleId')}&from=0&size=1&match=`)
  let referredArticle = getNestData(artData1)
  let availableArticle = getNestData(artData2)

  const message = {
    amount: payload.amount,
    replacementData: payload.replacementItem.toJS(),
    referredArticle,
    availableArticle,
  }
  sendIframeMsg('ADD_TO_CART', message)
}

export function* addArticleToCart({ payload }: ReturnType<typeof productActions.addArticleToCart>) {
  const { data: artData } = yield call(apiCaller, 'search', `id=${payload.item.get('idArtykulu')}&from=0&size=1&match=`)
  let articleData = getNestData(artData)

  const message = {
    amount: payload.amount,
    articleData,
  }
  sendIframeMsg('ADD_TO_CART', message)
}
