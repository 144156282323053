import React from 'react'
import { Grid, Cell } from 'styled-css-grid'
import { Wrapper, Header, Body, Label, Value, Container, CloseButton } from 'src/styles/popover'
import { isImmutable } from 'immutable'

type OwnProps = {
  label?: string
  onClose: () => void
  product: any
  productType: any
}

type Props = OwnProps

type ProductDetailsPopoverState = {
  moreOptions: boolean
}

class ProductDetailsPopover extends React.PureComponent<Props> {
  backdropClickHandler = e => {
    const { onClose } = this.props
    e.preventDefault()
    const target = e.target as HTMLDivElement
    if (target.id === 'backdrop') {
      onClose()
    }
  }

  getDiscountThresholdsList = p => {
    let discountThresholdsList = JSON.parse(p.get('discount_thresholds_list') || null)

    if (!Array.isArray(discountThresholdsList) || !discountThresholdsList.length) {
      discountThresholdsList = p.get('discount_thresholds') ? p.get('discount_thresholds').split('/').map(t => ({
        threshold: t,
        price: p.get('buy_price'),
      })) : []
    }

    return discountThresholdsList
  }

  render() {
    const { product, onClose } = this.props

    if (!product || !isImmutable(product)) return null

    const discountThresholdsList = this.getDiscountThresholdsList(product)

    return (
      <Container id="backdrop" onClick={this.backdropClickHandler}>
        <Wrapper>
          <Header>
            Szczegóły artykułu <CloseButton onClick={onClose}>✕</CloseButton>
          </Header>
          <Body>
            <Grid columns={12} gap={'24px'}>
              <Cell width={6}>
                <Label>Artykuł</Label>
                <Value>
                  {product.get('name')}
                  <br />
                  {product.get('producer_name')}
                </Value>
              </Cell>
              <Cell width={6}>
                <Label>Stan Poznań</Label>
                <Value>{product.get('stock_poznan')}</Value>
              </Cell>

              <Cell width={6}>
                <Label>Opis</Label>
                <Value>
                  {product.get('description')}
                  <br />
                  {product.get('description_2')}
                </Value>
              </Cell>
              <Cell width={6}>
                <Label>Stan filie</Label>
                <Value>
                  {
                    product.get('stock_gorzow') +
                    product.get('stock_zielona_gora') +
                    product.get('stock_wloclawek') +
                    product.get('stock_szczecinek') +
                    product.get('stock_lodz') +
                    product.get('stock_swiebodzice') +
                    product.get('stock_grajewo') +
                    product.get('stock_bydgoszcz') +
                    product.get('stock_tczew') +
                    product.get('stock_gliwice') +
                    product.get('stock_other')
                  }
                </Value>
              </Cell>

              <Cell width={6}>
                <Label>Cena katalogowa</Label>
                <Value>{product.get('catalog_price')}</Value>
              </Cell>
              <Cell width={6}>
                <Label>Stan ogółem</Label>
                <Value>{product.get('stock')}</Value>
              </Cell>

              <Cell width={6}>
                <Label>Cena detaliczna</Label>
                <Value>{product.get('retail_price')}</Value>
              </Cell>
              <Cell width={6}>
                <Label>Stan min</Label>
                <Value>{product.get('stock_min')}</Value>
              </Cell>
              <Cell width={2}>
                <Label>Ilości</Label>
                {
                    discountThresholdsList.map(item => <Value key={item.threshold}>{item.threshold}</Value>)
                }
              </Cell>
              <Cell width={4}>
                <Label>Cena zakupu</Label>
                {
                    discountThresholdsList.map(item => <Value key={item.threshold}>{item.price.toFixed(2)}</Value>)
                }
              </Cell>
              <Cell width={6}>
                <Label>Stan max</Label>
                <Value>{product.get('stock_max')}</Value>
              </Cell>

              <Cell width={6}>
                <Label>śr. Cena zakupu</Label>
                <Value>{product.get('average_buy_price')}</Value>
              </Cell>
              <Cell width={6}>
                <Label>Rozchód roczny</Label>
                <Value>{product.get('annual_expenditure')}</Value>
              </Cell>

              <Cell width={6}>
                <Label>śr. Cena sprzedaży</Label>
                <Value>{product.get('average_sell_price')}</Value>
              </Cell>
              <Cell width={6}>
                <Label>Zamówiono</Label>
                <Value>{product.get('ordered_sum')}</Value>
              </Cell>
            </Grid>
          </Body>
        </Wrapper>
      </Container>
    )
  }
}
export default ProductDetailsPopover
