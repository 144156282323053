import theme from 'src/theme'

export const autoCompleteTheme = {
  container: {
    position: 'relative',
    width: '100%',
    zIndex: 10,
  },
  input:{
    fontSize: '12px',
  },
  suggestionsContainerOpen: {
    boxSizing: 'content-box',
    display: 'block',
    position: 'absolute',
    top: '20px',
    left: '-11px',
    padding: '10px 10px 0px 10px',
    backgroundColor: 'white',
    width: '100%',
    border: `1px solid ${theme.colors.primary}`,
    borderTop: '0px',
    borderRadius: '0px 0px 5px 5px',
    zIndex: 10,
  },
  suggestion: {
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  suggestionHighlighted: {
    color: `${theme.colors.primary}`,
  },
}
