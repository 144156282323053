import React from 'react'
import SearchPanel from 'src/containers/SearchPanel/SearchPanel'
import SearchResultsGrid from 'src/containers/SearchResultsGrid/SearchResultGrid'
import ReplacementsResultGrid from 'src/containers/ReplacementsResultsGrid/ReplacementsResultsGrid'
import IframeListener from 'src/containers/IframeListener'
import DescriptionFilter from 'src/containers/DescriptionFilter/DescriptionFilter'

import { Layout, DescriptionContainer, SearchPanelContainer, ResultContainer } from './styles'

const App: React.FC<{}> = () => (
  <>
    <IframeListener />

    {/*<SearchPanel />*/}
    {/*<ReplacementsResultGrid />*/}
    {/*<SearchResultsGrid />*/}

    <Layout>
      <DescriptionContainer>
        <DescriptionFilter/>
      </DescriptionContainer>

      <SearchPanelContainer>
        <SearchPanel/>
      </SearchPanelContainer>

      <ResultContainer>
        <ReplacementsResultGrid/>
        <SearchResultsGrid/>
      </ResultContainer>
    </Layout>
  </>
)

export default App
