import styled from 'styled-components'

import arrowLeft from './arrow-left.svg';
import arrowRight from './arrow-right.svg';

export const Wrapper = styled.div`
  height: 100%;
  
  .image-slider {
    cursor: pointer;
    button {
      background-repeat: no-repeat !important;
      background-color: transparent !important;
      background-position: center center !important;
      width: 50px;
      height: 50px;
      margin-left: -30px !important;
      margin-right: -30px !important;
      
      img: {
        width: 25px;
        height: 25px;
      }
      
      img[alt="slide to left"] {
        content: url(${arrowLeft}) !important;
      }

      img[alt="slide to right"] {
        content: url(${arrowRight}) !important;
      }
    }
  }
`