import { equivalentsAvailability, equivalentsAvailabilityLabels } from 'src/constants'
import { clarionTime, dateToClarion } from 'src/utils'

export const parseAvailabilityTooltip = item => {
  const status = item.get('status').toLowerCase()

  if (item.get('date') === 99999) {
    return equivalentsAvailabilityLabels.NIEPOTWIERDZONE
  }

  switch (status) {
    case equivalentsAvailability.PRZYJECIE_W_TOKU:
    case equivalentsAvailability.DOSTEPNE_POZNAN:
    case equivalentsAvailability.STOMIL_STOCKLIST:
      return equivalentsAvailabilityLabels.Z_MAGAZYNU
    case (status.match(equivalentsAvailability.ZAMOWIONO_W) || {}).input:
      return (clarionTime(item.get('date')) as Date).toLocaleDateString('pl-PL', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      })
    case equivalentsAvailability.DOSTEPNE_FILIE:
      return equivalentsAvailabilityLabels.FILIE
    case equivalentsAvailability.MOZLIWA_PRODUKCJA_POZNAN:
    case equivalentsAvailability.MOZLIWA_PRODUKCJA_ZESPOLY:
      return equivalentsAvailabilityLabels.PRODUKCJA
    case (status.match(equivalentsAvailability.STOCKLIST) || {}).input: // others stocklist's
      return (item.get('date') - dateToClarion(new Date())) + ' dni'
    default:
      // for Picard and others providers
      return (item.get('date') - dateToClarion(new Date())) + ' dni'
  }
}

export const parseAvailabilitySecondaryTooltip = item => {
  const itemDate = item.get('date')
  const status = item.get('status').toLowerCase()
  const currentClarionDate = dateToClarion(new Date())

  if (equivalentsAvailability.ZAMOWIONO_W.test(status) && itemDate !== 0 && currentClarionDate > itemDate) {
    return 'Dostawa spóźniona'
  }

  return null
}

export const parseAvailabilityBase = (item, isStore = false) => {
  const status = item.get('status').toLowerCase()
  let days = item.get('date') - dateToClarion(new Date())
  let label = `${days} dni`
  if (isStore && days < 0) {
    label = equivalentsAvailabilityLabels.DOSTAWA_SPOZNIONA
  }
  return (status === equivalentsAvailability.DOSTEPNE_POZNAN || days === 0 ) ? equivalentsAvailabilityLabels.Z_MAGAZYNU : label
}