import { createReducer } from 'redux-act'
import * as sfA from 'src/actions/searchForm'
import * as srA from 'src/actions/searchResults'
import { fromJS } from 'immutable'

export default createReducer(
  {
    [sfA.searchApiRequest.toString()]: state => state.set('loading', true),
    [sfA.searchApiSuccess.toString()]: (state, { data, concatResults, replaceEsDescriptions }) => {
      let results = fromJS(data)
      if (concatResults) {
        results = state.updateIn(['results', 'hits', 'hits'], arr => arr.concat(fromJS(data.hits.hits))).get('results')
      }
      return state
        .set('results', results)
        .set('dirty', true)
        .set('error', false)
        .set('loading', false)
    },
    [sfA.searchApiFailure.toString()]: (state, error) =>
      state
        // .set('results', fromJS([]))
        .set('error', error)
        .set('loading', false),
    [srA.clearSearchResults.toString()]: state =>
      state
        .set('results', fromJS([]))
        .set('dirty', false)
        .set('error', false)
        .set('loading', false),
  },
  fromJS({
    dirty: false,
    loading: false,
    error: null,
    results: [],
  })
)
