import React from 'react'
import { fromJS } from 'immutable'

import { Wrapper, Label, Children } from './styles'

export interface SubcategoryItemProps {
  category: any
  categoryTreeCode: string
  onClick: (items: any[]) => void
  filter: any
}

class SubcategoryItem extends React.Component<SubcategoryItemProps> {
  render() {
    const { categoryTreeCode, onClick, filter, category, ...rest } = this.props

    const treeLevel = category.get('treeLevel', 0)
    const itemTreeCode = category.get('treeCode', 0)
    let children = category.get('children', fromJS({}))
    const abc = categoryTreeCode && categoryTreeCode.substr(0, treeLevel * 3)

    if(filter){
      children = children.filter(filter)
    }

    return (
      <Wrapper
        onClick={e => {
          e.stopPropagation()
          onClick(category)
        }}
        level={treeLevel}
        isActive={abc === itemTreeCode}
        hasChildren={children.size > 0}
        {...rest}
      >
        <Label isActive={abc === itemTreeCode} hasChildren={children.size > 0} level={treeLevel}>
          {category.get('name')}
        </Label>
        <Children isActive={abc === itemTreeCode} hasChildren={children.size > 0} level={treeLevel}>
          {children
            .entrySeq()
            .sortBy(([k, c]) => c.get('treeCode'))
            .map(([k, c]) => (
              <SubcategoryItem key={k} onClick={() => onClick(c)} category={c} categoryTreeCode={categoryTreeCode} filter={filter} />
            ))}
        </Children>
      </Wrapper>
    )
  }
}

export default SubcategoryItem
