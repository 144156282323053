import styled from 'styled-components'

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.18);
  // pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: #fff;
  pointer-events: auto;
  /* position: fixed; */
  /* //top: 50%; */
  /* left: 50%; */
  /* z-index: 20; */
  /* transform: translate3d(-50%, -50%, 0); */
  box-shadow: ${({ theme }) => theme.shadows.big};
`

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 8px;
  opacity: 0.7;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.58px;
  padding: 10px;
  position: relative;
`

export const Body = styled.div`
  color: #333;
  padding: 24px;
`

export const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 48px;
`

export const RowCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const Label = styled.div`
  font-size: 10px;
  line-height: 1.7;
  letter-spacing: 0.58px;
  text-transform: uppercase;
  color: #828282;
`

export const Value = styled.div`
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.56px;
  color: #3f3f3f;
`

export const Image = styled.img`
  display: block;
  width: 100%;
`
