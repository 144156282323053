import styled from 'styled-components'

export const Wrapper = styled.div<{}>`
  font-size: 14px;
  display: block;
  letter-spacing: 1.18px;
  font-family: Roboto, sans-serif;
  padding: 20px 36px;
`

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.formLabel};
  font-weight: bold;
  margin-right: 28px;
`

export const Text = styled.span`
  color: black;
  font-weight: normal;
`

export const Separator = styled.span`
  color: black;
  font-weight: normal;
`

export const Segment = styled.span`
  ${Text}{
    cursor: pointer;
    text-decoration: underline;
  }

  &:hover {
    ${Text}{
      color: ${({ theme }) => theme.colors.primaryHover};
    }
  }
  
  &:last-child {
    ${Text}{
      cursor: auto;
      font-weight: bold;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.primary}
    }
  }
`
