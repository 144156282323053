import { put, select } from 'redux-saga/effects'

import { saveLocation } from '../actions/router'
import { AnyAction } from 'redux'

export function* locationChange({ payload }: AnyAction) {
  const { location, isFirstRendering, action } = payload
  const state = yield select()
  const previousPathname = state.get(['router', 'location', 'pathname'])
  console.info(`Action: ${action}, first rendering: ${isFirstRendering}. Location before: ${previousPathname}, after: ${location.pathname}`)

  yield put(saveLocation(location))
}
