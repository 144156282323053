import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import * as a from 'src/actions/producersCatalogs'

export default createReducer(
  {
    [a.producersCatalogsApiRequest.toString()]: state => state.set('loading', true),
    [a.producersCatalogsApiSuccess.toString()]: (state, results) =>
      state
        .set('results', fromJS(results))
        .set('error', false)
        .set('loading', false),
    [a.producersCatalogsApiFailure.toString()]: (state, error) => state.set('error', error),
  },
  fromJS({})
)
