import React from 'react'

import { Label } from '../InputTextField/styles'

import { ItemWithCheckbox, ItemsWrapper, Wrapper } from './styles'

import { MultiSelectProps } from '.'

const SelectableItem: React.FC<any> = ({ selected, label, onClick }) => (
  <ItemWithCheckbox selected={selected} onClick={onClick}>
    {label}
  </ItemWithCheckbox>
)

class MultiSelect extends React.Component<MultiSelectProps> {
  private handleItemClick = (value, e) => {
    const { selectedItems, items, getValue, onChange } = this.props
    if (selectedItems.includes(value)) {
      onChange(selectedItems.filter(i => i !== value))
    } else {
      onChange(selectedItems.concat((items || []).map(getValue).filter(i => i.indexOf(value, 0) === 0)))
    }
  }

  render() {
    const { hasError, items, selectedItems, onChange, getValue, ...rest } = this.props
    return (
      <Label hasError={hasError} style={{ height: '100%' }}>
        <Wrapper hasError={hasError} {...rest}>
          <ItemsWrapper>
            {
              items
                .reduce((arr, v) => {
                  arr[selectedItems.includes(getValue(v)) ? 0 : 1].push(v)

                  return arr
                }, [[], []])
                .reduce((arr, v, index) => {
                  let isSelected = index === 0

                  let val = v.sort((a, b) => getValue(a).localeCompare(getValue(b))).map(v => (
                    <SelectableItem
                      label={this.props.renderLabel({ item: v, selected: isSelected })}
                      key={v}
                      selected={isSelected}
                      onClick={e => this.handleItemClick(getValue(v), e)}
                    />
                  ))

                  return [...arr, ...val]
                }, [])
            }
          </ItemsWrapper>
        </Wrapper>
      </Label>
    )
  }
}

export default MultiSelect
