import styled from 'styled-components'

export const Layout = styled.div`
  width: 100%;
  height: 100%;
  
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: auto 1fr;
  gap: 0 20px;
`

export const DescriptionContainer = styled.div`
  grid-area: 1 / 1 / 3 / 2;
`

export const SearchPanelContainer = styled.div`
  grid-area: 1 / 2 / 2 / 3;
`

export const ResultContainer = styled.div`
  grid-area: 2 / 2 / 3 / 3;
`
