import React from 'react'
import Autosuggest from 'react-autosuggest'
import TagsInput from 'src/components/TagsInput'

import { autoCompleteTheme as theme } from './styles'

const AutoCompleteRenderInput = ({ addTag, suggestionsArray, ...props }) => {
  const handleOnChange = (e, { newValue, method }) => {
    if (method === 'enter') {
      e.preventDefault()
    } else {
      props.onChange(e)
    }
  }

  const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
  const inputLength = inputValue.length

  let suggestions = suggestionsArray.filter(item => {
    return item.name.toLowerCase().slice(0, inputLength) === inputValue
  })

  return (
    <Autosuggest
      ref={props.ref}
      suggestions={suggestions}
      shouldRenderSuggestions={value => value && value.trim().length > 0}
      getSuggestionValue={suggestion => suggestion.name}
      renderSuggestion={suggestion => <span>{suggestion.name}</span>}
      inputProps={{ ...props, placeholder: props.placeholder, onChange: handleOnChange }}
      onSuggestionSelected={(e, { suggestion }) => {
        addTag(suggestion.name)
      }}
      onSuggestionsClearRequested={() => {}}
      onSuggestionsFetchRequested={() => {}}
      theme={theme}
    />
  )
}

/*
suggestionsArray = [{name: 'name1'},...,{name: 'lastName'}]
 */

const AutoCompleteTagInput: React.FC<any> = ({ suggestionsArray, ...rest }) => {
  if (suggestionsArray.length === 0) return <TagsInput {...rest} />

  return <TagsInput renderInput={({ addTag, ...props }) => AutoCompleteRenderInput({ addTag, ...props, suggestionsArray })} {...rest} />
}

export default AutoCompleteTagInput
