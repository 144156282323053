import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { saveLocation } from 'src/actions/router'
import { fromJS } from 'immutable'
import Popover from 'react-tiny-popover'
import SubcategoryItem from 'src/components/SubcategoryItem'
import debounce from 'lodash.debounce'
import CategoryItem from 'src/components/CategoryItem/CategoryItem'
import CategoryArrow from 'src/components/CategoryItem/CategoryArrow'
import orionTheme from 'src/theme'
import { LabelText, Label } from 'src/components/InputTextField/styles'
import { SEARCH_WRAPPER_STORE } from 'src/constants/basic'

import { Wrapper, SubcategoriesWrapper } from './styles'

const PREVENT_CLOSE = false

type OwnProps = {
  onChange: (treeCode: string, ids: Number[], tolerance?: string) => void
}

const mapStateToProps = (state, ownProps: OwnProps) => ({
  wrapper: state.getIn(['settings', 'wrapper']),
  categories: state.getIn(['categories', 'results'], fromJS({})),
  error: state.getIn(['categories', 'error']),
  loading: state.getIn(['categories', 'loading']),
  categoryTreeCode: state.getIn(['searchForm', 'categoryTreeCode'], ''),
})

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
  actions: bindActionCreators(
    {
      saveLocation,
    },
    dispatch
  ),
})

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps

class SearchResultsGrid extends React.PureComponent<Props> {
  private debouncedSetOpenPopover: any
  private debouncedSetOpenNowPopover: any
  private debouncedSetClosePopover: any

  constructor(props) {
    super(props)

    this.debouncedSetOpenPopover = debounce(this.setOpenPopover, 500)
    this.debouncedSetOpenNowPopover = debounce(this.setOpenPopover, 0)
    this.debouncedSetClosePopover = debounce(this.setClosePopover, 0)
  }

  state = {
    openPopover: null,
  }

  private setOpenPopover = openPopover => {
    // console.log({ openPopover })
    if (PREVENT_CLOSE && !openPopover) return null
    this.setState({
      openPopover,
    })
  }

  private setClosePopover = openPopover => {
    // console.log({ openPopover })
    if (PREVENT_CLOSE && !openPopover) return null
    this.setState({
      openPopover,
    })
  }

  private handleCategoryItemClick = c => {
    const { onChange, categoryTreeCode } = this.props

    const clickedTheSame = c.get('treeCode') === categoryTreeCode
    const clickedMasterParent = c.get('treeCode').length === 3 && categoryTreeCode.includes(c.get('treeCode'))

    const treeCode = (clickedTheSame || clickedMasterParent) ? '' : c.get('treeCode')
    const tolerance = (clickedTheSame || clickedMasterParent) ? '' : c.get('tolerance')
    const catIds = (clickedTheSame || clickedMasterParent)
      ? []
      : c
          .get('childrenMultiLevelCatIds', fromJS([]))
          .push(c.get('multiLevelCatId'))
          .toJS()

    this.setClosePopover('')
    onChange(treeCode, catIds, tolerance)
  }

  render() {
    const {
      categories,
      loading,
      // error,
      categoryTreeCode,
      wrapper
    } = this.props

    if (!categories) return null

    let cats = categories.valueSeq().sortBy(c => c.get('treeCode', fromJS([])))
    const storeMode = wrapper === SEARCH_WRAPPER_STORE

    const filter = storeMode && (cc => cc.get('inStore'))

    if(filter){
      cats = cats.filter(filter)
    }

    const catsSize = cats.count()

    return (
      <div>
        <Label hasError={false}>
          <LabelText>Filtruj dział</LabelText>
        </Label>
        <Wrapper>
          {loading ? (
            <div>Ładowanie działów</div>
          ) : (
            cats.map((c, k) => {
              const count = c.get('childrenMultiLevelCatIds', fromJS([])).size;
              let children = c.get('children')

              if(filter){
                children = children.filter(filter)
              }

              return (
                <Popover
                  key={k}
                  padding={0}
                  disableReposition
                  isOpen={this.state.openPopover === c.get('treeCode')}
                  position={'bottom'} // preferred position
                  align={k > 4 ? 'end' : 'start'}
                  containerStyle={{ position: 'relative', overflow: 'visible', zIndex: '10' }}
                  content={({ position, targetRect, popoverRect }) => (
                    !!count && !!children.size ? (
                      <CategoryArrow // if you'd like an arrow, you can import the ArrowContainer!
                        position={position}
                        targetRect={targetRect}
                        popoverRect={popoverRect}
                        arrowColor={c.get('treeCode') === (categoryTreeCode && categoryTreeCode.substr(0, 3)) ? orionTheme.colors.primary : '#fff'}
                        arrowSize={10}
                        onMouseEnter={() => this.debouncedSetOpenNowPopover(c.get('treeCode'))}
                      >
                        <SubcategoriesWrapper
                          onMouseEnter={() => this.debouncedSetOpenNowPopover(c.get('treeCode'))}
                          onMouseLeave={() => this.debouncedSetClosePopover('')}
                          style={{
                            ...(k >= catsSize / 2 && { borderTopRightRadius: 0 }),
                            ...(k < catsSize / 2 && { borderTopLeftRadius: 0 }),
                            columnCount: Math.ceil(c.get('childrenMultiLevelCatIds', fromJS([])).size / 11),
                          }}
                        >
                          {children
                            .entrySeq()
                            .map(([k1, c1]) => {
                              return (
                                <SubcategoryItem
                                  key={k1}
                                  onClick={this.handleCategoryItemClick}
                                  category={c1}
                                  categoryTreeCode={categoryTreeCode}
                                  filter={filter}
                                  // isActive={c1.get('treeCode') === (categoryTreeCode && categoryTreeCode.substr(0,3))}
                                >
                                  {c1.get('name')}
                                  {c1.get('treeCode')}
                                </SubcategoryItem>
                              )
                            })}
                        </SubcategoriesWrapper>
                      </CategoryArrow>
                    ) : <span/>
                  )}
                >
                  <CategoryItem
                    key={k}
                    category={c}
                    onClick={() => this.handleCategoryItemClick(c)}
                    onMouseEnter={() => this.debouncedSetOpenNowPopover(c.get('treeCode'))}
                    onMouseLeave={() => this.debouncedSetClosePopover('')}
                    isActive={c.get('treeCode') === (categoryTreeCode && categoryTreeCode.substr(0, 3))}
                    isExpanded={this.state.openPopover === c.get('treeCode') && count > 0}
                  />
                </Popover>
              )
            })
          )}
        </Wrapper>
      </div>
    )
  }
}

// export default Button
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultsGrid)
