import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import * as a from 'src/actions/settings'
import { SEARCH_WRAPPER_STORE } from 'src/constants/basic'

export default createReducer(
  {
    [a.setSettings.toString()]: (state, settings) =>
      state
        .merge(fromJS(settings)),
  },
  fromJS({
    wrapper: SEARCH_WRAPPER_STORE, // SEARCH_WRAPPER_CRK
    loggedInAs: 'c',
    token: '',
    search: true,
    cart: true,
    priceGate: 0,
    exactQuantities: false,
    discountGroup: null,
    gId: null,
    cId: null
  }),
)
