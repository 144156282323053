import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import localStorage from 'src/utils/localStorage'
import { iframeMsgTypes } from 'src/constants'
import { changeOptionsValue, resetToDefaultSearchSettings } from 'src/actions/searchForm'
import { setSettings } from 'src/actions/settings'
import { showReplacementsTableByProductId } from 'src/actions/replacements'

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      setSettings,
      changeOptionsValue,
      resetToDefaultSearchSettings,
      showReplacementsTableByProductId,
    },
    dispatch
  ),
})

export interface IframeListenerProps {
  actions: any
}

const IframeListener: React.FC<IframeListenerProps> = ({ actions }) => {
  const clearStorage = () => {
    localStorage.clearAll()
  }

  const messageHandler = event => {
    const { msgType, msgData } = event.data

    switch (msgType) {
      case iframeMsgTypes.CLEAR_STORE:
        clearStorage()
        actions.resetToDefaultSearchSettings()
        break
      case iframeMsgTypes.SET_SETTINGS:
        actions.setSettings(msgData)
        if(msgData.productId){
          actions.showReplacementsTableByProductId(msgData.productId)
        }
        // use != not !== to compare undefined too.
        if(msgData.showPrice != null){
          actions.changeOptionsValue('showPrice', msgData.showPrice ? '1' : '')
        }
        // use != not !== to compare undefined too.
        if(msgData.showBuyPrice != null){
          actions.changeOptionsValue('showBuyPrice', msgData.showPrice ? '1' : '')
        }
        break
      default:
        break
    }
  }

  window.addEventListener('message', messageHandler, false)

  return null
}

export default connect(
  null,
  mapDispatchToProps
)(IframeListener)
