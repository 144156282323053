import { applyMiddleware, createStore } from 'redux'
import middlewares from './middleware'
import createRootReducer from './reducers'
import history from './browserHistory'
import { fromJS } from 'immutable'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

export default function configureStore(preloadedState: any) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    fromJS(preloadedState),
    composeWithDevTools(
      applyMiddleware(
        ...middlewares
        // ... other middlewares ...
      )
    )
  )

  return store
}
