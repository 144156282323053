import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router' // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router/immutable'
import configureStore from 'src/configureStore'
import history from 'src/browserHistory'
import App from 'src/components/App'
import sagas from 'src/sagas'
import { sagaMiddleware } from 'src/middleware'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import reset from 'styled-reset'
import theme from 'src/theme'

import * as serviceWorker from './serviceWorker'

require('typeface-roboto')

const GlobalStyles = createGlobalStyle`
  ${reset}
  body {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    overflow-y: scroll;
  }
  * {
    box-sizing: border-box;
  }
  /* other styles */
  .collapse-css-transition {
    width: 100%;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  /* html-wrapper */
  .html-wrapper {
    overflow: auto;
    
    cursor: auto;
    margin: 16px 0;
  
    table {
      width: 100%;
      margin: 8px 0;
    }
    
    td {
      padding: 4px;
    }
    
    table, th, td {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }
  }
  
  /* react-simple-image-slider */
  .image-slider {
    position: relative !important;
    & > div:first-child > div:first-child > div {
      background-size: contain !important;
      background-repeat: no-repeat;
      background-position: center;
    }
    
    button {
      background: rgba(0,0,0, 0.1) !important;
      border-radius: 8px;
    }
  }
`
export const rootNode: HTMLElement | null = document.getElementById('root')

const store = configureStore({
  // searchForm: {
  //   query: {
  //     match: '6204',
  //     availability: 'availOrig7days',
  //     size: 30,
  //     from: 0,
  //   },
  // },
})

sagaMiddleware.run(sagas)

ReactDOM.render(
  <>
    <GlobalStyles />
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {' '}
        {/* place ConnectedRouter under Provider */}
        <ThemeProvider theme={theme}>
          <>
            {' '}
            {/* your usual react-router v4/v5 routing */}
            <Switch>
              <Route exact path="/" render={() => <App />} />
              <Route render={() => <div>Miss</div>} />
            </Switch>
          </>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  </>,
  rootNode
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
