export default function toApproximateQuantities(number: number): string {
  if (!Number.isInteger(number) || number <= 0) {
    return '0'
  }

  if (number === 1) {
    return '> 0'
  }

  const stringNumber = number.toString()
  const exp = stringNumber.length - 1

  if (number > Number(5 + '0'.repeat(exp))) {
    return '> ' + Number(5 + '0'.repeat(exp))
  } else if (number > Number(1 + '0'.repeat(exp))) {
    return '> ' + Number(1 + '0'.repeat(exp))
  } else {
    return '> ' + Number(5 + '0'.repeat(exp - 1))
  }
}