import styled from 'styled-components'

import { ToggleButtonProps } from '.'

export const Wrapper = styled.button<ToggleButtonProps>`
  height: 80px;
  width: 70px;
  font-size: 10px;
  box-shadow: none;
  font-weight: bold;
  border-radius: 8px;
  display: block;
  appearance: none;
  outline: none;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  min-width: 70px;
  color: #404040;
  padding: 4px 8px;
  margin: 2px 10px 0;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.formBorder};

  &.active {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
  }
  &:hover {
    box-shadow: ${({ theme }) => theme.defaultHover};
  }

`

export const Icon = styled.div<{ isExpanded: boolean, padding?: number }>`
  width: 16px;
  height: 16px;
  display: inline-block;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  border-right: 2px solid ${({ theme }) => theme.colors.primary};
  transform: rotate(${({ isExpanded }) => (isExpanded ? '45deg' : '-45deg')});
  transition: transform 0.7s;
`

export const LabelText = styled.div`
  margin-bottom: 8px;
  
  &:hover {
    text-decoration: none;
  }
`
