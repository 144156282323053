import { createReducer } from 'redux-act'
import * as a from 'src/actions/categories'
import { fromJS } from 'immutable'

export default createReducer(
  {
    [a.categoriesApiRequest.toString()]: state => state.set('loading', true),
    [a.categoriesApiSuccess.toString()]: (state, results) =>
      state
        .set('results', fromJS(results))
        .set('error', false)
        .set('loading', false),
    [a.categoriesApiFailure.toString()]: (state, error) => state.set('error', error),
    [a.catDimLabelsApiRequest.toString()]: state => state.set('loading', true),
    [a.catDimLabelsApiSuccess.toString()]: (state, results) =>
      state
        .merge(fromJS(results))
        .set('error', false)
        .set('loading', false),
    [a.catDimLabelsApiFailure.toString()]: (state, error) => state.set('error', error),
  },
  fromJS({
    size: 20,
    from: 0,
  })
)
