import fetchData from 'src/utils/fetchData'

import { call, select } from 'redux-saga/effects'

const getSettings = (state) => state.get('settings')

export default function* apiCaller(uri: string, queryString: string) {
  let settings = yield select(getSettings)

  let mode = settings.get('wrapper')
  let loggedInAs = settings.get('loggedInAs')
  let token = settings.get('token')
  let discountGroup = settings.get('discountGroup')
  let cId = settings.get('cId')
  let gId = settings.get('gId')

  let header = {
    'Search-Config-Mode': mode,
    'Search-Config-Type': loggedInAs,
    'Search-Config-Token': token,
    'Search-Config-Dg': discountGroup,
    'Search-Config-cId': cId,
    'Search-Config-gId': gId,
  }

  return yield call(fetchData, uri, queryString, header)
}