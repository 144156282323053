import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'
import ReactTooltip from 'react-tooltip'
import {
  showReplacementsTable,
  showReplacementProductInfo,
  hideReplacementProductInfo,
  showReplacementProductDetails,
  hideReplacementProductDetails,
} from 'src/actions/replacements'
import { loadProductData, loadProductDetails, addReplacementToCart } from 'src/actions/product'
import { changeFilterValue, clearQueryForAlternativeSearch } from 'src/actions/searchForm'
import Portal from 'src/components/Portal/Portal'
import ReplacementsBaseProductInfo from 'src/containers/ReplacementsBaseProductInfo'
import Button from 'src/components/Button'
import ProductInfoPopover from 'src/containers/ProductInfoPopover'
import ProductDetailsPopover from 'src/containers/ProductDetailsPopover'
import { InlineInput } from 'src/components/InputTextField/styles'
import {
  parseAvailabilityTooltip,
  parseAvailabilitySecondaryTooltip,
  parseAvailabilityBase,
} from 'src/containers/ReplacementsResultsGrid/utils/parseAvailabilityTooltip'
import TransportDetailsPopover from 'src/containers/TransportDetailsPopover'
import WarningPopover from 'src/containers/WarningPopover'
import toApproximateQuantities from 'src/utils/parsers'
import { SEARCH_WRAPPER_CRK, SEARCH_WRAPPER_STORE } from 'src/constants'
import ReactHtmlParser from 'react-html-parser';
import Collapse from '@kunukn/react-collapse'
import { ImageSliderWrapper, TableCellDesc } from 'src/containers/SearchResultsGrid/styles'
import ToggleTextButton from 'src/components/ToggleTextButton'
import { getImageUrl, sendIframeMsg } from 'src/utils'
import SimpleImageSlider from 'react-simple-image-slider'
import { setMore } from 'src/actions/cache'
import SliderWrapper from 'src/components/SliderWrapper/SliderWrapper'
import { equivalentsAvailabilityLabels } from 'src/constants'
import bearingImg from '../SearchResultsGrid/bearing.svg'

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  HeaderRow,
  HeaderLabel,
  LoadingBearing,
  SecondaryInfo,
  SvgButton,
  Dimensions,
} from './styles'

const INFO_POPOVER = 'info'
const DETAILS_POPOVER = 'details'
const TRANSPORT_POPOVER = 'transport'
const WARNING_POPOVER = 'warning'

type OwnProps = {
  label?: string
}

const mapStateToProps = (state, ownProps: OwnProps) => ({
  wrapper: state.getIn(['settings', 'wrapper']),
  showCart: state.getIn(['settings', 'cart']),
  priceGate: state.getIn(['settings', 'priceGate']),
  exactQuantities: state.getIn(['settings', 'exactQuantities']),
  items: state.getIn(['replacements', 'results', 'aggregations', 'replacement', 'result', 'hits', 'hits'], fromJS([])).map(i => i.get('_source').set('_score', i.get('_score'))),
  total: state.getIn(['replacements', 'results', 'hits', 'total'], fromJS({})),
  error: state.getIn(['replacements', 'error']),
  loading: state.getIn(['replacements', 'loading']),
  baseProduct: state.getIn(['replacements', 'baseProduct']),
  productDetails: state.getIn(['replacements', 'productDetails']),
  popoverProduct: state.getIn(['product', 'results']),
  imageLabels: state.getIn(['categories', 'imgDimLabels']),
  dimLabels: state.getIn(['categories', 'catDimLabels']),
  categories: state.getIn(['categories', 'results']),
  popoverItemId: state.getIn(['replacements', 'popoverItemId']),
  popoverItemType: state.getIn(['replacements', 'popoverItemType']),
  showPrice: state.getIn(['searchForm', 'showPrice']),
  showBuyPrice: state.getIn(['searchForm', 'showBuyPrice']),
  producersCatalogs: state.getIn(['producersCatalogs', 'results']),
  // unit: state.getIn(['replacements', 'baseProduct','jednostka']),
  more: state.getIn(['cache', 'more']),
  transportPossibilities: state.getIn(['transportPossibilities', 'results']),
})

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
  actions: bindActionCreators(
    {
      setMore,
      showReplacementsTable,
      showReplacementProductInfo,
      hideReplacementProductInfo,
      showReplacementProductDetails,
      hideReplacementProductDetails,
      loadProductData,
      loadProductDetails,
      addReplacementToCart,
      clearQueryForAlternativeSearch,
      changeFilterValue,
    },
    dispatch,
  ),
})

type State = {
  itemId: Number | null
  amounts: any
  openPopover: String | null
  hover: number | undefined
  popoverTransportStatus: String | null
  popoverId: Number | null
  replacement: Boolean | null
  inProduction: Boolean | null
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class ReplacementsResultsGrid extends React.PureComponent<Props, State> {
  state = {
    itemId: null,
    amounts: fromJS({}),
    openPopover: null,
    hover: undefined,
    popoverTransportStatus: '',
    popoverId: null,
    replacement: false,
    inProduction: false,
  }

  componentDidUpdate(): void {
    ReactTooltip.rebuild()
  }

  isCrk = (wrapper = '') => {
    return (wrapper || this.props.wrapper) === SEARCH_WRAPPER_CRK
  }

  isStore = (wrapper = '') => {
    return (wrapper || this.props.wrapper) === SEARCH_WRAPPER_STORE
  }

  notInStorage = (item) => {
    return (item.get('amount') === 0 && parseAvailabilityTooltip(item) === equivalentsAvailabilityLabels.Z_MAGAZYNU)
  }

  private openPopoverInfo = item => {
    const productId = item.get('describedArticleId')
    this.props.actions.showReplacementProductInfo(productId, item.get('id'), item.get('type'))
    this.props.actions.loadProductData(productId)
    this.setState({
      openPopover: INFO_POPOVER,
    })
  }

  private closePopoverInfo = () => {
    this.setState({
      openPopover: null,
    })
    this.props.actions.hideReplacementProductInfo()
  }

  private openPopoverDetails = item => {
    const productId = item.get('describedArticleId')
    this.props.actions.showReplacementProductDetails(productId, item.get('id'))
    this.props.actions.loadProductDetails(productId)
    this.setState({
      openPopover: DETAILS_POPOVER,
    })
  }

  private closePopoverDetails = () => {
    this.setState({
      openPopover: null,
    })
    this.props.actions.hideReplacementProductDetails()
  }

  private openPopoverTransport = (itemStatus, id) => {
    this.setState({
      openPopover: TRANSPORT_POPOVER,
      popoverTransportStatus: itemStatus,
      popoverId: id
    })
  }

  private openPopoverWarning = (item) => {
    this.setState({
      openPopover: WARNING_POPOVER,
      popoverId: item.get('id'),
      inProduction: item.get('status').includes('produkcja'),
      replacement: item.get('type') === 'RÓWNOWAŻNIK',
    })
  }

  private closePopoverWarning = () => {
    this.setState({
      openPopover: null,
      popoverId: null,
      inProduction: false,
      replacement: false,
    })
  }

  private closePopoverTransport = () => {
    this.setState({
      openPopover: null,
      popoverTransportStatus: '',
      popoverId: null
    })
  }

  isMore = (id) => this.props.more.get(id + '')
  
  toggleMore = async (item) => {
    const id = item.get('id')
    const status = this.isMore(id)

    this.props.actions.setMore(id, !status);
  }

  updateAmount = async (id, value, min?, max?) => {
    let val = value
      .replace(/[^0-9.,]/g, '')
      .replace(',', '.')
      .trim()

    await this.setState({
      amounts: this.state.amounts.set(id, val),
    })
  }

  addReplacementToCart = async item => {
    const id = item.get('id')
    if (!this.state.amounts.has(id)) {
      await this.updateAmount(id, '1')
    }

    this.props.actions.addReplacementToCart(item, this.state.amounts.get(id))
  }

  handleMouseEnter = item => {
    let id = setTimeout(() => {
      this.props.actions.setMore(item.get('id'), true);
    }, 3000)


    this.setState({
      hover: id,
    });
  }

  handleMouseLeave = () => {
    const {
      hover,
    } = this.state

    clearTimeout(hover)
  }

  showImagePopup = (url, current) => {
    sendIframeMsg('IMAGE_POPUP', { images: url, current: current})
  }

  showWarning = (item) => {
    return (item.get('type') === 'RÓWNOWAŻNIK' || item.get('status').includes('produkcja') )
  }

  valuationProduct = item => {
    sendIframeMsg('VALUATION_PRODUCT', { articleNumber: item.get('describedArticleId'), date: item.get('date'), amount: item.get('amount') });
  }

  searchByAlternativeName = alternativeName => {
    this.props.actions.clearQueryForAlternativeSearch()
    this.props.actions.changeFilterValue([{ name: 'name', value: alternativeName }])
  }

  renderDimensions(item) {
    const w = item.get('wymiarW')
    const s = item.get('wymiarS')
    const g = item.get('wymiarG')

    const dimensions: Array<number> = []

    if (w) dimensions.push(w.toFixed(2))
    if (s) dimensions.push(s.toFixed(2))
    if (g) dimensions.push(g.toFixed(2))

    return dimensions.length ? <Dimensions>({dimensions.join(' x ')})</Dimensions> : null
  }

  renderCrkHeader() {
    const {
      showCart,
      showPrice,
      showBuyPrice,
    } = this.props

    return (
      <HeaderRow wrapper={SEARCH_WRAPPER_CRK} showPrice={showPrice === '1'} showBuyPrice={showBuyPrice === '1'} showCart={showCart}>
        <TableCell style={{ alignItems: 'flex-start', paddingLeft: 36, }}>
          <HeaderLabel>Oznaczenie</HeaderLabel>
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          <HeaderLabel>PRODUCENT</HeaderLabel>
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          <HeaderLabel>OPIS</HeaderLabel>
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          <HeaderLabel>Ilości progowe</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>Ilość</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>J.M.</HeaderLabel>
        </TableCell>
        {showBuyPrice === '1' && (
          <TableCell>
            <HeaderLabel>Cena zak.</HeaderLabel>
          </TableCell>
        )}
        {showPrice === '1' && (
          <TableCell style={{ alignItems: 'flex-end' }}>
            <HeaderLabel>Cena det.</HeaderLabel>
          </TableCell>
        )}
        <TableCell>
          <HeaderLabel>Dostępność</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>Info</HeaderLabel>
        </TableCell>
        {showCart && (
          <TableCell style={{ alignItems: 'flex-end', paddingRight: 36 }}>
            <HeaderLabel>Do koszyka</HeaderLabel>
          </TableCell>
        )}
      </HeaderRow>
    )
  }

  renderStoreHeader() {
    const {
      showCart,
      showPrice,
      showBuyPrice,
    } = this.props

    return (
      <HeaderRow wrapper={SEARCH_WRAPPER_STORE} showPrice={showPrice === '1'} showBuyPrice={showBuyPrice === '1'} showCart={showCart}>
        <TableCell style={{ alignItems: 'flex-start', paddingLeft: 36 }}>
          <HeaderLabel>Oznaczenie</HeaderLabel>
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          <HeaderLabel>PRODUCENT</HeaderLabel>
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          <HeaderLabel>OPIS</HeaderLabel>
        </TableCell>
        <TableCell/>
        <TableCell>
          <HeaderLabel>J.M.</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>Ilość</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>Cena netto</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>Wysyłka</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>Do koszyka</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>Informacja dodatkowe</HeaderLabel>
        </TableCell>
      </HeaderRow>
    )
  }

  renderCrkProductRow(item) {
    const {
      showCart,
      popoverItemId,
      showPrice,
      showBuyPrice,
    } = this.props

    const imagesString = item.get('rysunek')
    const images = imagesString && imagesString.split(';')
    const imagesObj = images && images.map(img => ({ url: getImageUrl(img)}))

    return (
      <TableRow
        wrapper={SEARCH_WRAPPER_CRK}
        showCart={showCart}
        showPrice={showPrice === '1'}
        showBuyPrice={showBuyPrice === '1'}
        key={item.get('id')}
        onMouseEnter={() => this.handleMouseEnter(item)}
        onMouseLeave={() => this.handleMouseLeave()}
        className={item.get('id') === popoverItemId ? 'infoShown' : ''}
      >
        <TableCell style={{ alignItems: 'flex-start', paddingLeft: 36 }}>{item.get('nameOriginal')}</TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>{item.get('producer')}</TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          {(item.get('html') || imagesObj) && (
            <ToggleTextButton
              padding={8}
              label={this.isMore(item.get('id')) ? 'Mniej' : 'Więcej'}
              isExpanded={this.isMore(item.get('id'))}
              onClick={(e) => {
                e.stopPropagation()
                this.toggleMore(item)
              }}/>
          )}
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>{item.get('thresholds')}</TableCell>
        <TableCell>{item.get('amount')}</TableCell>
        <TableCell>{item.get('unit')}</TableCell>
        {showBuyPrice === '1' && <TableCell>{item.get('pricePLN', 'b/d')}</TableCell>}
        {showPrice === '1' && <TableCell>{item.get('priceRetail', 'b/d')}</TableCell>}
        <TableCell>
          {/*<div style={{ textAlign: 'center' }}>{item.get('status', 'b/d')}</div>*/}
          <div style={{ textAlign: 'center' }}>{parseAvailabilityTooltip(item)}</div>
          {item.get('orderId') > 0}
          <SecondaryInfo>{parseAvailabilitySecondaryTooltip(item)}</SecondaryInfo>
        </TableCell>
        <TableCell style={{ alignItems: 'center', flexDirection: 'row' }}>
          <Button
            withCircle
            icon={'?'}
            data-tip={parseAvailabilityTooltip(item)}
            data-for="tooltip"
            onClick={() => this.openPopoverDetails(item)}
          />
          <Button
            withCircle
            icon={item.get('status', '')[0].toUpperCase()}
            onClick={() => this.openPopoverTransport(item.get('status'), item.get('id'))}
            data-tip={this.isStore() ? this.props.transportPossibilities.getIn([item.get('status'), 'description3']) : item.get('status')}
            data-for="tooltip"
          />
        </TableCell>
        {showCart && (
          <TableCell
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              paddingRight: 36,
            }}
          >
            <InlineInput
              onChange={e => this.updateAmount(item.get('id'), e.target.value)}
              type="text"
              placeholder={item.get('jednostka', 'szt.')}
              value={this.state.amounts.get(item.get('id'), '1')}
            />
            <Button
              withCircle
              icon={'+'}
              onClick={() => {
                this.addReplacementToCart(item)
              }}
            />
          </TableCell>
        )}
        <TableCellDesc style={{ paddingLeft: 36 }}>
          <Collapse isOpen={this.isMore(item.get('id'))}>
            <div style={{display: 'flex'}} onClick={(event) => {event.stopPropagation()}}>
              <div className={'html-wrapper'} style={{width: '75%'}}>{ReactHtmlParser(item.get('html'))}</div>
              { imagesObj && (
                <ImageSliderWrapper style={{width: '25%'}}>
                  { this.isMore(item.get('id')) && (
                    <SliderWrapper>
                      <SimpleImageSlider
                        width={'100%'}
                        height={'100%'}
                        images={imagesObj}
                        navStyle={2}
                        bgColor={'#fff'}
                        showBullets={false}
                        onClick={(idx, event) => {
                          event.stopPropagation();
                          this.showImagePopup(imagesObj, idx)
                        }}
                      />
                    </SliderWrapper>
                  )}
                </ImageSliderWrapper>
              )}
            </div>
          </Collapse>
        </TableCellDesc>
      </TableRow>
    )
  }

  renderStoreProductRow(item) {
    const {
      showCart,
      popoverItemId,
      priceGate,
      exactQuantities,
    } = this.props

    const imagesString = item.get('rysunek')
    const images = imagesString && imagesString.split(';')
    const imagesObj = images && images.map(img => ({ url: getImageUrl(img)}))

    return (
      <TableRow
        wrapper={SEARCH_WRAPPER_STORE}
        showCart={showCart}
        showPrice={false}
        showBuyPrice={false}
        key={item.get('id')}
        onMouseEnter={() => this.handleMouseEnter(item)}
        onMouseLeave={() => this.handleMouseLeave()}
        className={item.get('id') === popoverItemId || item.get('id') === this.state.popoverId ? 'infoShown' : ''}
      >
        <TableCell style={{ alignItems: 'flex-start', paddingLeft: 36 }}>
          {item.get('nameOriginal')}
          {item.get('type') === 'RÓWNOWAŻNIK' && <SecondaryInfo>produkt równoważny</SecondaryInfo>}
          {item.get('type') === 'Alternatywa' && <SecondaryInfo>produkt alternatywny</SecondaryInfo>}
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>{item.get('producer')}</TableCell>
        <TableCell style={{alignItems: 'flex-start'}}>
          {item.get('elasticsearchOpis')} {item.get('info')}
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          {(item.get('html') || imagesObj) && (
            <ToggleTextButton
              padding={8}
              label={this.isMore(item.get('id')) ? 'Mniej' : 'Więcej'}
              isExpanded={this.isMore(item.get('id'))}
              onClick={(e) => {
                e.stopPropagation()
                this.toggleMore(item)
              }}/>
          )}
        </TableCell>
        <TableCell>{item.get('unit')}</TableCell>
        <TableCell>
          {!this.notInStorage(item) ?
            exactQuantities ? item.get('amount') : toApproximateQuantities(item.get('amount'))
          : ''
        }
        </TableCell>
        <TableCell style={{ alignItems: 'center', flexDirection: 'row' }}>
          {item.get('priceForClient') === 1 ? (item.get('pricePLN') > 0 ? item.get('pricePLN') : 'zapytaj') :
            !this.notInStorage(item) ? 
              item.get('pricePLN') && priceGate > item.get('pricePLN') ? item.get('pricePLN') : 'na zapytanie'
              : ''
          }
          {!(
            <SvgButton style={{ marginLeft: '4px' }}
                       src="/icons/b_hands.svg"
                       onClick={(e) => {
                         e.stopPropagation()
                       }}
            />
          )}
        </TableCell>
        <TableCell style={{ textAlign: 'center'}}>
          {!this.notInStorage(item) ? parseAvailabilityBase(item, this.isStore()) : 'brak na magazynie'}
        </TableCell>
        <TableCell style={{ alignItems: 'center', flexDirection: 'row' }}>
          {(item.get('amount') && item.get('pricePLN') && (priceGate > item.get('pricePLN') || item.get('priceForClient') === 1  )) ? (
            <>
              <InlineInput
                onChange={e => this.updateAmount(item.get('id'), e.target.value, 1, item.get('amount'))}
                type="text"
                placeholder={item.get('jednostka', 'szt.')}
                value={this.state.amounts.get(item.get('id'), '1')}
              />
              <Button
                withCircle
                icon={'+'}
                onClick={(e) => {
                  this.addReplacementToCart(item)
                }}
              />
            </>
            ) : (
            <Button label={'Zapytaj'}
                    onClick={(e) => {
                      e.stopPropagation()
                      this.valuationProduct(item)
                    }}
            />
          )}
        </TableCell>
        <TableCell style={{ alignItems: 'center', flexDirection: 'row' }}>
          {
            this.showWarning(item) && (
              <SvgButton style={{ marginRight: '4px' }}
                src="/icons/b_alert.svg"
                onClick={() => this.openPopoverWarning(item)}
                data-tip="uwagi"
                data-for="tooltip"
              />
            )
          }
          <SvgButton
            src="/icons/b_transport.svg"
            onClick={() => this.openPopoverTransport(item.get('status'), item.get('id'))}
            data-tip={this.isStore() ? this.props.transportPossibilities.getIn([item.get('status'), 'description3']) : item.get('status')}
            data-for="tooltip"
          />
        </TableCell>
        <TableCellDesc style={{ paddingLeft: 36 }}>
          <Collapse isOpen={this.isMore(item.get('id'))}>
            <div style={{display: 'flex'}} onClick={(event) => {event.stopPropagation()}}>
              <div className={'html-wrapper'} style={{width: '75%'}}>{ReactHtmlParser(item.get('html'))}</div>
              { imagesObj && (
                <ImageSliderWrapper style={{width: '25%'}}>
                  { this.isMore(item.get('id')) && (
                    <SliderWrapper>
                      <SimpleImageSlider
                        width={'100%'}
                        height={'100%'}
                        images={imagesObj}
                        navStyle={2}
                        bgColor={'#fff'}
                        showBullets={false}
                        onClick={(idx, event) => {
                          event.stopPropagation();
                          this.showImagePopup(imagesObj, idx)
                        }}
                      />
                    </SliderWrapper>
                  )}
                </ImageSliderWrapper>
              )}
            </div>
          </Collapse>
        </TableCellDesc>
      </TableRow>
    )
  }

  renderEmptyRow(baseProduct) {
    return (      <TableRow
      wrapper={SEARCH_WRAPPER_STORE}
      showCart={false}
      showPrice={false}
      showBuyPrice={false}
      style={{ gridTemplateColumns: 'auto', cursor: 'pointer', color: '#ffffff', background: '#408eba' }}
      onClick={() => this.searchByAlternativeName(baseProduct.get('nazwa'))}
    >
      <TableCell>Zobacz wszystkie równoważniki</TableCell>
    </TableRow>)
  }

  render() {
    const {
      items,
      error,
      total,
      loading,
      baseProduct,
      popoverProduct,
      popoverItemId,
      imageLabels,
      dimLabels,
      categories,
      popoverItemType,
      producersCatalogs,
    } = this.props

    return (
      <div>
        <ReplacementsBaseProductInfo/>
        {baseProduct && (
          <>
            <Table>
              <TableBody>
                {this.isCrk() && this.renderCrkHeader()}
                {this.isStore() && this.renderStoreHeader()}
                {items.map((item) => (this.isCrk() && this.renderCrkProductRow(item)) || (this.isStore() && this.renderStoreProductRow(item)))}
                {this.renderEmptyRow(baseProduct)}
              </TableBody>
            </Table>
            <div style={{ opacity: 0.5, textAlign: 'center', padding: 10, fontSize: 12 }}>
              {items.size || 0} z {total.get('relation', 'eq') !== 'eq' ? 'ponad' : ''} {total.get('value', 0)} pozycji
              dostępności
            </div>
          </>
        )}
        <Portal key="loader">
          <LoadingBearing src={bearingImg} className={loading ? 'loading' : ''}/>
        </Portal>
        {this.state.openPopover === INFO_POPOVER && baseProduct && popoverProduct && popoverItemId && (
          <Portal key="productInfo">
            <ProductInfoPopover
              product={popoverProduct}
              imageLabels={imageLabels}
              dimLabels={dimLabels}
              categories={categories}
              productType={popoverItemType}
              producersCatalogs={producersCatalogs}
              onClose={() => this.closePopoverInfo()}
            />
          </Portal>
        )}
        {this.state.openPopover === DETAILS_POPOVER && baseProduct && popoverProduct && popoverItemId && (
          <Portal key="productDetails">
            <ProductDetailsPopover product={popoverProduct} productType={popoverItemType}
                                   onClose={() => this.closePopoverDetails()}/>
          </Portal>
        )}
        <Portal key="transportPossibilities">
          {this.state.openPopover === TRANSPORT_POPOVER && (
            <TransportDetailsPopover status={this.state.popoverTransportStatus}
                                     isStore={this.isStore()}
                                     onClose={() => this.closePopoverTransport()}/>
          )}
        </Portal>
        <Portal key="warningInformation">
          {this.state.openPopover === WARNING_POPOVER && (
            <WarningPopover inProduction={this.state.inProduction}
                            replacement={this.state.replacement}
                            onClose={() => this.closePopoverWarning()}/>
          )}
        </Portal>
        <ReactTooltip id="tooltip"/>
        {error && <pre style={{ color: 'red' }}>{JSON.stringify(error, null, 2)}</pre>}
      </div>
    )
  }
}

// export default Button
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReplacementsResultsGrid)
