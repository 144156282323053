export const getParentMultiLevelCatId = (multiLevelCatId: number, categories) => {
  const multiLevelCatIdString = multiLevelCatId.toString()

  const foreparent = categories.find((category) => {
    const childrenMultiLevelCatIds = category.get('childrenMultiLevelCatIds')
    return (childrenMultiLevelCatIds && childrenMultiLevelCatIds.includes(multiLevelCatIdString))
  })

  if (foreparent) {
    const isParent = foreparent.get('children').some((category) => {
      return category.get('multiLevelCatId') === multiLevelCatIdString
    })

    if (isParent) {
      return foreparent.get('multiLevelCatId')
    } else {
      return getParentMultiLevelCatId(multiLevelCatId, foreparent.get('children'))
    }
  }
}

export const findCategoryLabelsDimensions = (multiLevelCatId, catDimLabels, categories) => {
  const category = catDimLabels.get(multiLevelCatId.toString())

  if (category) {
    return category.get('dimensions').toJS()
  } else {
    return findCategoryLabelsDimensions(getParentMultiLevelCatId(multiLevelCatId, categories), catDimLabels, categories)
  }
}
