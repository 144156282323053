import { AnyAction } from 'redux'
import { put, call } from 'redux-saga/effects'
import * as a from 'src/actions/producers'
import apiCaller from 'src/utils/apiCaller'

export function* fetch(action: AnyAction) {
  yield put(a.producersApiRequest())
  try {
    const { data } = yield call(apiCaller, 'producers', '')
    let parsedData = []
    if ((data.aggregations && data.aggregations.producers, data.aggregations.producers.buckets))
      parsedData = data.aggregations.producers.buckets.map(producer => ({ name: producer.key }))
    yield put(a.producersApiSuccess(parsedData))
  } catch (err) {
    console.log(err.response)
    yield put(a.producersApiFailure((err.response && err.response.data) || err))
  }
}
