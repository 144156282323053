import React from 'react'
import Immutable from 'immutable'

import { DefaultLabelWrapper, DefaultLabelText } from './styles'

import { SelectableItemType } from '.'

export type LabelType = SelectableItemType & {
  item: Immutable.Map<string, any>
  defaultEmptyText?: string
}

export const DefaultLabel: React.FC<LabelType> = ({ item, selected, defaultEmptyText = '-- BRAK OPISU --' }) => {
  const label = item.get('key', '')
  const empty = label.length === 0
  return (
    <DefaultLabelWrapper selected={selected}>
      <DefaultLabelText title={!empty ? label : defaultEmptyText} selected={selected} style={{ fontStyle: empty ? 'italic' : 'normal' }}>
        {!empty ? label : defaultEmptyText}
      </DefaultLabelText>
      {/* <DefaultLabelMeta selected={selected}>{item.get('doc_count', 0)}</DefaultLabelMeta> */}
    </DefaultLabelWrapper>
  )
}
