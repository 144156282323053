import styled from 'styled-components'

import { ErrorAwareInput } from '.'

export const Input = styled.input<ErrorAwareInput>`
  border: 1px solid ${({ hasError, theme }) => (hasError ? theme.colors.error : theme.colors.formBorder)};
  color: ${({ hasError, theme }) => (hasError ? theme.colors.error : theme.colors.formLabel)};
  border-radius: ${({ theme }) => theme.borderRadius};
  height: 30px;
  line-height: 30px;
  padding: 0px 10px;
  padding-right: ${({ hasIcon }) => (hasIcon ? '32px' : '10px')}
  outline: none;
  display: block;
  width: 100%;

  &:hover {
    /* box-shadow: ${({ theme }) => theme.defaultHover}; */
  }

  &:active,
  &:focus {
    border-color: ${({ hasError, theme }) => (hasError ? theme.colors.error : theme.colors.primary)};
  }
  
  &::placeholder {
    color: #ccc;
    font-style: italic;
  }
`

export const InlineInput = styled.input`
  line-height: 22px;
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.formBorder};
  border-radius: 4px;
  height: 24px;
  line-height: 24px;
  width: 40px;
  margin-right: 5px;

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.colors.formBorder};
  }

  &::placeholder {
    color: #ccc;
  }

  &:active::placeholder,
  &:focus::placeholder {
    color: transparent;
  }
`

export const Label = styled.label<ErrorAwareInput>`
  position: relative;
  display: block;
  width: 100%;
  color: ${({ hasError, theme }) => (hasError ? theme.colors.error : theme.colors.formLabel)};
  font-family: Roboto, sans-serif;
  font-size: 10px;
  line-height: 15px;
  font-weight: bold;
  letter-spacing: 1.1px;
  // height: 100%;
`

export const LabelText = styled.div`
  /* color: currentColor; */
  padding: 0 10px;
  margin-bottom: 2px;
  min-height: 15px;
`

export const Icon = styled.img`
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 24px;
  right: 28px;
`
export const IconClear = styled.img`
  cursor: pointer;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 24px;
  right: 6px;
`