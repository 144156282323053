import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import * as a from 'src/actions/warningInfo'

export default createReducer(
  {
    [a.warningInfoApiRequest.toString()]: state => state.set('loading', true),
    [a.warningInfoApiSuccess.toString()]: (state, results) =>
      state
        .set('results', fromJS(results))
        .set('error', false)
        .set('loading', false),
    [a.warningInfoApiFailure.toString()]: (state, error) => state.set('error', error),
  },
  fromJS({})
)
