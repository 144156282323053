import { createAction } from 'redux-act'

export const categoriesApiTrigger = createAction('CATEGORIES_API_TRIGGER')
export const categoriesApiRequest = createAction('CATEGORIES_API_REQUEST')
export const categoriesApiSuccess = createAction<any>('CATEGORIES_API_SUCCESS')
export const categoriesApiFailure = createAction<any>('CATEGORIES_API_FAILURE')

export const catDimLabelsApiTrigger = createAction('CAT_DIM_LABELS_API_TRIGGER')
export const catDimLabelsApiRequest = createAction('CAT_DIM_LABELS_API_REQUEST')
export const catDimLabelsApiSuccess = createAction<any>('CAT_DIM_LABELS_API_SUCCESS')
export const catDimLabelsApiFailure = createAction<any>('CAT_DIM_LABELS_API_FAILURE')
