import styled from 'styled-components'
import { mobileSBreakpoint, desktopSBreakpoint } from 'src/styles/breakpoints'

export const Panel = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.paddingBig};
  position: relative;
`

type FlexContainerProps = {
  wrap?: boolean
}

export const FlexContainer = styled.div`
  display: flex;
  flex-flow: row ${(props: FlexContainerProps) => (props.wrap ? 'wrap' : 'nowrap')};
`

export const LeftColumn = styled.div`
  width: 100%;
`

export const RightColumn = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1;
  @media (min-width: ${mobileSBreakpoint}) {
    flex-flow: row nowrap;
  }
`

export const Row = styled.div`
  display: flex;
  width: 100%;
`

export const MultiSelectWrapper = styled(Row)`
  display: none;
  @media (min-width: ${desktopSBreakpoint}) {
    display: initial;
  }
`

export const FlexGrowContent = styled.div`
  flex: 1;
`

export const Warning = styled.div`
  color: red;
`

export const Grid = styled.div`
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  display: grid;
  height: auto;
  grid-auto-flow: row;
  grid-auto-rows: minmax(20px, auto);
  grid-gap: 10px 20px;

  // @media (min-width: ${desktopSBreakpoint}) {
  //   grid-template-columns: 166px 105px 105px 130px 120px 1fr;
  // }
`
export const Wrapper = styled.div`
 width: 880px;
`

export const GridDimensions = styled.div`
  display: grid;
  height: auto;
  grid-auto-flow: row dense;
  grid-auto-rows: minmax(20px, auto);
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-gap: 20px;
`

export const NewSearchButtonWrapper = styled.div`
  width: 100%;
  @media (min-width: 350px) {
    padding-right: 20px;
  }
`
export const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  text-transform: uppercase;
  padding-top: 13px;
`

export const Badges = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.formLabel};
  padding: 8px;
  margin: 4px 0;
  font-size: 12px;
  background-color: white;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.formBorder};
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
  }
`
type LabelStyledProps = { decoration?: string }

export const Label = styled.div<LabelStyledProps>`
  font-size: 10px;
  line-height: 1.7;
  font-weight: bold;
  letter-spacing: 0.58px;
  text-transform: ${({decoration}) => (decoration || 'uppercase')};
  white-space: nowrap;
`