import { imagesUrl, imagesExt } from 'src/config'
export { sendIframeMsg } from './iframe'

export const dayInMilliseconds = 24 * 60 * 60 * 1000

export const clarionTime = value => {
  const clarionDate = parseInt(value, 10)

  // 61730 is a fixed number of days to unix epoch since Clarion date start December 28, 1800
  const $timeFromEpoch = (clarionDate - 61730) * dayInMilliseconds

  return new Date($timeFromEpoch)
}

export const dateToClarion = (date: Date) => Math.floor((date.getTime()) / dayInMilliseconds) + 61730

export const getImageUrl = (imagePath: string) => `${imagesUrl}${imagePath.replace(' ', '_')}.${imagesExt}`