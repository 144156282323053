import styled from 'styled-components'
import { desktopSBreakpoint } from 'src/styles/breakpoints'

interface CategoryItemProps {
  isActive: boolean
}

const SIZE = 80
const SPACE = 3

export const Wrapper = styled.div`
  /*
  display: flex;
  flex-flow: row wrap;
   */
  width: 100%;
  @media (min-width: ${desktopSBreakpoint}) {
    width: 790px;
  }
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 70px);
  min-height: ${SIZE + 4 * SPACE}px;
  position: relative;
`

export const CategoryItem = styled.div<CategoryItemProps>`
  background-color: ${({ isActive }) => (isActive ? '#fff' : '#fff')};
  font-size: 10px;
  display: flex;
  /*margin: ${SPACE}px;
  
   */
  height: ${SIZE}px;
  width: 70px;
  border-radius: ${({ theme }) => theme.borderRadiusBig};
  border: 1px solid ${({ theme }) => theme.colors.formBorder};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ccc;
  }
`

export const SubcategoriesWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 6px 8px 0 rgba(64, 142, 186, 0.15);
  border-radius: 8px;
  border: 1px solid #e2e2e2;
  padding: 20px;
  font-size: 10px;
  /* display: inline-grid; */
  max-height: 260px;
  max-width: 90vw;
  /* grid-auto-flow: column;
  grid-template-rows: repeat(12, auto);
  grid-gap: 0px 20px; */
  column-fill: balance-all;
  column-width: auto;
`

export const CategoryItemLabel = styled.div`
  padding: 0 5px;
  text-align: center;
  max-width: 250px;
  word-break: break-all;
`
