import { createAction } from 'redux-act'

export const showReplacementsTable = createAction<any>('Show replacements table', (product, availability, producers, sort, statuses) => ({
  product,
  availability,
  producers,
  sort,
  statuses
}))
export const showReplacementsTableByProductId = createAction<any>('Show replacements table', (productId, availability, producers, sort, statuses) => ({
  productId,
  availability,
  producers,
  sort,
  statuses,
}))
export const hideReplacementsTable = createAction('Hide replacements table')
export const showReplacementProductInfo = createAction('Show replacement product info', (id, replId,type) => ({
  id,
  replId,
  type,
}))
export const hideReplacementProductInfo = createAction('Hide replacement product info')
export const showReplacementProductDetails = createAction('Show replacement product details', (id, replId) => ({
  id,
  replId,
}))
export const hideReplacementProductDetails = createAction('Hide replacement product details')

export const replacementsApiTrigger = createAction('replacements_API_TRIGGER')
export const replacementsApiRequest = createAction('replacements_API_REQUEST')
export const replacementsApiSuccess = createAction<any>('replacements_API_SUCCESS')
export const replacementsApiFailure = createAction<any>('replacements_API_FAILURE')
