import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  changeFilterValue,
  setPreserveEsDescriptions,
} from 'src/actions/searchForm'
import { fromJS } from 'immutable'
import MultiSelect from 'src/components/MultiSelect/MultiSelect'
import { DefaultLabel } from 'src/components/MultiSelect'
import { clearMore } from 'src/actions/cache'

const mapStateToProps = (state) => ({
  selectedDescriptions: state.getIn(['searchForm', 'query', 'esDescriptions'], fromJS([])),
  categoryDescriptions: state.getIn(['searchForm', 'categoryDescriptions'], fromJS([])),
})

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      clearMore,
      setPreserveEsDescriptions,
      changeFilterValue,
    },
    dispatch,
  ),
})

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

class DescriptionFilter extends React.PureComponent<Props> {
  private onChangeEsDescriptions = (value: any) => {
    if (value.size) {
      this.props.actions.setPreserveEsDescriptions(true)
    }

    this.props.actions.clearMore()
    this.props.actions.changeFilterValue([{ name: 'esDescriptions', value }, { name: 'fullMatch', value: '' }])
  }

  render() {
    const { categoryDescriptions, selectedDescriptions } = this.props

    return (
      <MultiSelect
        items={categoryDescriptions}
        selectedItems={selectedDescriptions}
        renderLabel={({ item, selected }) => <DefaultLabel item={item} selected={selected}/>}
        getValue={item => item.get('key')}
        onChange={items => {
          this.onChangeEsDescriptions(items)
        }}
      />
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DescriptionFilter)
