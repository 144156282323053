const PRZYJECIE_W_TOKU = 'przyjęcie w toku'
const DOSTEPNE_POZNAN = 'dostępne poznań'
const DOSTEPNE_FILIE = 'dostępne filie'
const MOZLIWA_PRODUKCJA_POZNAN = 'możliwa produkcja - poznań'
const MOZLIWA_PRODUKCJA_ZESPOLY = 'możliwa produkcja - zespoły'
const GATES_STOCKLIST = 'gates stocklist'
const IKO_STOCKLIST = 'iko stocklist'
const NACHI_STOCKLIST = 'nachi stocklist'
const PICARD = 'picard'
const RENOLD_STOCKLIST = 'renold stocklist'
const STOMIL_STOCKLIST = 'stomil stocklist'
const ZAMOWIONO_W = /zamówiono w/  // {company name} ->
const STOCKLIST = /stocklist/

export default {
  PRZYJECIE_W_TOKU,
  DOSTEPNE_POZNAN,
  DOSTEPNE_FILIE,
  MOZLIWA_PRODUKCJA_POZNAN,
  MOZLIWA_PRODUKCJA_ZESPOLY,
  GATES_STOCKLIST,
  IKO_STOCKLIST,
  NACHI_STOCKLIST,
  PICARD,
  RENOLD_STOCKLIST,
  STOMIL_STOCKLIST,
  ZAMOWIONO_W,
  STOCKLIST
}
