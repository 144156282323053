import { BaseHTMLAttributes } from 'react'

import component from './ToggleButton'

export interface ToggleButtonProps extends BaseHTMLAttributes<any> {
  label?: string
  padding?: number,
  isExpanded: boolean
}

export default component
