import React from 'react'
import { connect } from 'react-redux'
import { Grid, Cell } from 'styled-css-grid'
import ReactHtmlParser from 'react-html-parser';
import { Wrapper, Header, Body, Value, Container, CloseButton } from 'src/styles/popover'

const mapStateToProps = state => ({
  warningInfo: state.getIn(['warningInfo', 'results']),
})


const WarningPopover = ({ onClose, replacement, inProduction, warningInfo }) => {

  const backdropClickHandler = e => {
    e.preventDefault()
    const target = e.target as HTMLDivElement
    if (target.id === 'backdrop') {
      onClose()
    }
  }

  return (
    <Container id="backdrop" onClick={backdropClickHandler}>
      <Wrapper style={{ minWidth: '200px', maxWidth: '50%' }}>
        <Header>
          Uwagi <CloseButton onClick={onClose}>✕</CloseButton>
        </Header>
        <Body>
          <Grid columns={'1'} gap={'24px'}>
            <Cell>
              {replacement && <Value>{ReactHtmlParser(warningInfo.get('replacement'))}</Value>}
              {inProduction && <Value>{ReactHtmlParser(warningInfo.get('inProduction'))}</Value>}
            </Cell>
          </Grid>
        </Body>
      </Wrapper>
    </Container>
  )
}

export default connect(mapStateToProps)(WarningPopover)
