import React from 'react'

import { Wrapper, Icon, LabelText } from './styles'

import { ToggleTextButtonProps } from '.'

const ToggleTextButton: React.FC<ToggleTextButtonProps> = ({ label, isExpanded, padding, children, ...rest }) => (
  <Wrapper isExpanded={isExpanded} padding={padding} {...rest}>
    <Icon isExpanded={isExpanded} padding={padding} />
    {label && <LabelText>{label}</LabelText>}
    {children}
  </Wrapper>
)

export default ToggleTextButton
