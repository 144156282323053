import styled from 'styled-components'
import { SEARCH_WRAPPER_CRK, SEARCH_WRAPPER_STORE } from 'src/constants'

const getGridColumnsTemplate = (wrapper: string) => {
  switch (wrapper) {
    case SEARCH_WRAPPER_CRK:
      return `2fr minmax(120px, auto) 120px 120px 120px`
    case SEARCH_WRAPPER_STORE:
      return `2fr minmax(120px, auto) 120px 120px`
  }
}

type RowProps = {
  wrapper: string
}

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  font-size: 15px;
  padding: 15px 10px;
  min-height: 198px;
  margin-bottom: 48px;
`

export const InfoRow = styled.div`
  display: grid;
  grid-template-columns: ${({wrapper}: RowProps) => getGridColumnsTemplate(wrapper)};
`

export const RowCell = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const CollCell = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

export const FlatRowCell = styled.div`
  padding: 4px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
`
type LabelStyledProps = { decoration?: string }

export const Label = styled.div<LabelStyledProps>`
  font-size: 10px;
  line-height: 1.7;
  letter-spacing: 0.58px;
  text-transform: ${({decoration}) => (decoration || 'uppercase')};
  white-space: nowrap;
`

type ValueStyledProps = { leftPadded?: string }

export const Value = styled.div<ValueStyledProps>`
  font-size: 16px;
  letter-spacing: 0.56px;
  font-weight: bold;
  //white-space: nowrap;
  padding-left: ${({leftPadded}) => (leftPadded || 0)};
`

export const Bold = styled.span`
  font-weight: bold;
`

export const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: start;
  text-transform: uppercase
`

export const Badges = styled.div`
  width: 100%;
  color: white;
  padding: 4px 8px;
  margin: 2px 0;
  font-size: 12px;
  border-radius: 6px;
  border: 1px solid white;
  cursor: pointer;

  &.active {
    background-color: white;
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const ProducersContainer = styled.div`
    display: flex;
`

export const ProducersBadgesContainer = styled(BadgesContainer)`
    column-count: 2;
    max-height: 200px;
    margin-right: 4px;
`

export const ProducersBadges = styled(Badges)`
    margin-right: 4px;
`
