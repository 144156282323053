import { createAction } from 'redux-act'

export const loadProductData = createAction<any>('Load Product Data')
export const loadProductDetails = createAction<any>('Load Product Details')
export const productApiRequest = createAction<any>('PRODUCT_API_REQUEST')
export const productApiSuccess = createAction<any>('PRODUCT_API_SUCCESS')
export const productApiFailure = createAction<any>('PRODUCT_API_FAILURE')

export const addReplacementToCart = createAction('Add replacement to cart', (replacementItem: any, amount: number) => ({ replacementItem, amount }))
export const addArticleToCart = createAction('Add article to cart', (item: any, amount: number) => ({ item, amount }))
