export const SORT_LIST = [
  { name: 'domyślnie', value: '' },
  { name: 'cena', value: 'pricePLN' },
  { name: 'ilość', value: '-amount' },
  { name: 'termin', value: 'date' },
  { name: 'alfabetycznie', value: 'nameOriginal' },
  { name: 'producent', value: 'producer' },
]

export const STATUS_LIST = [
  { name: 'Magazyn', value: 'magazyn' },
  { name: 'Filie', value: 'filie' },
  { name: 'Zamówiono', value: 'zamowiono' },
  { name: 'Stocklist', value: 'stocklist' },
  { name: 'Dostawca', value: 'dostawca' },
]

export const AVAILABILITY_LIST = [
  { name: 'Dostępne z magazynu', value: 'availOrigNow' },
  { name: 'Do 2 dni', value: 'availOrig2days' },
  { name: 'Do 7 dni', value: 'availOrig7days' },
  { name: 'Do 30 dni', value: 'availOrig30days' },
  { name: 'Dowolna', value: 'availOrigLater' },
  { name: 'Wszystko', value: '' },
]

export const AVAILABILITY_LIST_STORE = [
  { name: 'Dostępne z magazynu', value: 'availOrigNow' },
  { name: 'Do 2 dni', value: 'availOrig2days' },
  { name: 'Do 7 dni', value: 'availOrig7days' },
  { name: 'Do 30 dni', value: 'availOrig30days' },
  { name: 'Dowolna', value: '' },
]