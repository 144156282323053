const Z_MAGAZYNU = 'z magazynu'
const FILIE = 'filie'
const PRODUKCJA = 'produkcja'
const NIEPOTWIERDZONE = 'niepotwierdzone'
const DOSTAWA_SPOZNIONA = 'dostawa spóźniona'

export default {
  Z_MAGAZYNU,
  FILIE,
  PRODUKCJA,
  NIEPOTWIERDZONE,
  DOSTAWA_SPOZNIONA,
}
