import React from 'react'

import { CategoryItemImage, CategoryItemLabel, Wrapper } from './styles'

import { CategoryItemProps } from '.'

const CategoryItem: React.FC<CategoryItemProps> = ({ isActive, category, isExpanded, ...rest }) => {
  return (
    <Wrapper {...rest} isActive={isActive} isExpanded={isExpanded}>
      <CategoryItemImage isActive={isActive} isExpanded={isExpanded} src={process.env.PUBLIC_URL + '/icons/' + category.get('icon')} alt={''} />
      <CategoryItemLabel title={category.get('name')}>{category.get('name')}</CategoryItemLabel>
    </Wrapper>
  )
}

export default CategoryItem
