import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router'
import searchForm from 'src/reducers/searchForm'
import searchResults from 'src/reducers/searchResults'
import categories from 'src/reducers/categories'
import replacements from 'src/reducers/replacements'
import product from 'src/reducers/product'
import settings from 'src/reducers/settings'
import producersCatalogs from 'src/reducers/producersCatalogs'
import transportPossibilities from 'src/reducers/transportPossibilities'
import warningInfo from 'src/reducers/warningInfo'
import producers from 'src/reducers/producers'
import cache from 'src/reducers/cache'

export default (history: any) =>
  combineReducers({
    router: connectRouter(history),
    searchForm,
    searchResults,
    categories,
    replacements,
    product,
    settings,
    producersCatalogs,
    transportPossibilities,
    producers,
    cache,
    warningInfo,
    // ... // rest of your reducers
  })
