import { AnyAction } from 'redux'
import * as replacementsActions from 'src/actions/replacements'
import { fromJS } from 'immutable'
import apiCaller from 'src/utils/apiCaller'

import { put, call } from 'redux-saga/effects'

export function* loadReplacementsData(action: AnyAction) {
  const { product, availability, producers, sort, statuses } = action.payload
  yield put(replacementsActions.replacementsApiRequest())
  try {
    const queryString = `id=${product.get('idArtykulu')}&availability=${availability}` + (producers ? `&producers=${producers}` : '') + (sort ? `&sort=${sort}` : '') + (statuses ? `&statuses=${statuses}` : '')
    const { data } = yield call(apiCaller, 'replacements', queryString)
    yield put(replacementsActions.replacementsApiSuccess({ data }))
  } catch (err) {
    console.log(err.response)
    yield put(replacementsActions.replacementsApiSuccess((err.response && err.response.data) || err))
  }
}

const getData = p => o => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)
const getNestProductData = getData(['aggregations', 'products', 'result', 'hits', 'hits', '0', '_source'])

export function* loadReplacementsDataByProductId(action: AnyAction) {
  const { productId, availability, producers, sort } = action.payload
  try {
    const productData = yield call(apiCaller, 'search', `id=${productId}&from=0&size=1&match=`)
    const product = fromJS(getNestProductData(productData.data))
    yield put(replacementsActions.showReplacementsTable(product, availability, producers, sort))
  } catch (err) {
    console.log(err.response)
  }
}