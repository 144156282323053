import React from 'react'

import { Wrapper, Icon, LabelText } from './styles'

import { ToggleButtonProps } from '.'

const ToggleButton: React.FC<ToggleButtonProps> = ({ label, isExpanded, padding, children, ...rest }) => (
  <Wrapper isExpanded={isExpanded} padding={padding} {...rest}>
    <LabelText>
      <div>{label}</div>
      <div>{children}</div>
    </LabelText>
    <Icon isExpanded={isExpanded} padding={padding} />
  </Wrapper>
)

export default ToggleButton
