import styled from 'styled-components'

import { ErrorAwareInput } from '.'

export const Label = styled.label<ErrorAwareInput>`
  display: block;
  width: 100%;
  color: ${({ hasError, theme }) => (hasError ? theme.colors.error : theme.colors.formLabel)};
  font-family: Roboto, sans-serif;
  font-size: 10px;
  line-height: 15px;
  font-weight: bold;
  letter-spacing: 1.1px;
`

export const LabelText = styled.div`
  /* color: currentColor; */
  padding: 0 10px;
  margin-bottom: 2px;
`
