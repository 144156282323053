import { BaseHTMLAttributes } from 'react'

import component from './ToggleTextButton'

export interface ToggleTextButtonProps extends BaseHTMLAttributes<any> {
  label?: string
  padding?: number,
  isExpanded: boolean
}

export default component
