import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { saveLocation } from 'src/actions/router'
import { fromJS } from 'immutable'
import InfiniteScroll from 'react-infinite-scroller'
import { loadMore } from 'src/actions/searchForm'
import Breadcrumbs from 'src/components/Breadcrumbs/Breadcrumbs'
import {
  changeCategory,
  changeFilterValue,
  clearEsDescriptions,
  showProductInfo,
  hideProductInfo,
} from 'src/actions/searchForm'
import { showReplacementsTable } from 'src/actions/replacements'
import Portal from 'src/components/Portal/Portal'
import Button from 'src/components/Button'
import ProductInfoPopover from 'src/containers/ProductInfoPopover'
import { addArticleToCart, loadProductData } from 'src/actions/product'
import { InlineInput } from 'src/components/InputTextField/styles'
import { AVAILABILITY_LIST, SEARCH_WRAPPER_CRK, SEARCH_WRAPPER_STORE } from 'src/constants'
import ReactHtmlParser from 'react-html-parser';
import Collapse from '@kunukn/react-collapse'
import ToggleTextButton from 'src/components/ToggleTextButton'
import toApproximateQuantities from 'src/utils/parsers'
import SimpleImageSlider from 'react-simple-image-slider'
import { getImageUrl, sendIframeMsg } from 'src/utils'
import SliderWrapper from 'src/components/SliderWrapper/SliderWrapper'

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableCellDesc,
  SecondaryInfo,
  HeaderRow,
  HeaderLabel,
  LoadingBearing,
  Wrapper,
  Message,
  ImageSliderWrapper,
  Dimensions,
} from './styles'
import bearingImg from './bearing.svg'
import { setMore } from 'src/actions/cache'

type OwnProps = {
  label?: string
}

const mapStateToProps = (state, ownProps: OwnProps) => ({
  wrapper: state.getIn(['settings', 'wrapper']),
  exactQuantities: state.getIn(['settings', 'exactQuantities']),
  items: state.getIn(['searchResults', 'results', 'hits', 'hits'],
    fromJS([]))
      .map(i => i.get('_source')
        .set('_score', i.get('_score'))
        .set('_amounts', i.getIn(['inner_hits', 'amounts', 'hits', 'hits'], []).map(i => i.get('_source')))
  ),
  total: state.getIn(['searchResults', 'results', 'hits', 'total'], fromJS({})),
  error: state.getIn(['searchResults', 'error']),
  dirty: state.getIn(['searchResults', 'dirty']),
  loading: state.getIn(['searchResults', 'loading']),
  size: state.getIn(['searchForm', 'query', 'size'], 0),
  from: state.getIn(['searchForm', 'query', 'from'], 0),
  query: state.getIn(['searchForm', 'query']),
  availability: state.getIn(['searchForm', 'query', 'availability']),
  categories: state.getIn(['categories', 'results']),
  categoryTreeCode: state.getIn(['searchForm', 'categoryTreeCode']),
  replacementsSearchMode: !!state.getIn(['replacements', 'baseProduct']),
  popoverProduct: state.getIn(['product', 'results']),
  imageLabels: state.getIn(['categories', 'imgDimLabels']),
  dimLabels: state.getIn(['categories', 'catDimLabels']),
  popoverItemId: state.getIn(['searchForm', 'popoverItemId']),
  popoverItemType: state.getIn(['replacements', 'popoverItemType']),
  producersCatalogs: state.getIn(['producersCatalogs', 'results']),
  more: state.getIn(['cache', 'more'])
})

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps) => ({
  actions: bindActionCreators(
    {
      setMore,
      clearEsDescriptions,
      //      setPreserveEsDescriptions,
      changeCategory,
      changeFilterValue,
      addArticleToCart,
      saveLocation,
      loadMore,
      showReplacementsTable,
      showProductInfo,
      hideProductInfo,
      loadProductData,
    },
    dispatch,
  ),
})

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

type State = {
  amounts: any,
  hover: number | undefined
}

class SearchResultsGrid extends React.PureComponent<Props, State> {
  state = {
    amounts: fromJS({}),
    hover: undefined,
  }

  private loadMore = () => {
    const { loading } = this.props
    if (!loading) {
      this.props.actions.loadMore(this.props.items.size, this.props.size)
    }
  }

  private onChangeCategory = (treeCode: string, ids: Number[]) => {
    // this.props.actions.setPreserveEsDescriptions(false)
    this.props.actions.clearEsDescriptions()

    this.props.actions.changeFilterValue([{ name: 'catIds', value: ids }])
    this.props.actions.changeCategory(treeCode, ids)
  }

  private renderItemAlternativesAvailability = item => {
    const { availability } = this.props
    // const availKeys = ['Now', '2days', '7days', '30days', 'Later']

    if (!availability) {
      return item.get('availReplLater')
      // let total = 0
      // for (const key of availKeys) {
      //   total += item.get('availRepl' + key, 0)
      // }
      // return total
    }

    return item.get(availability.replace('Orig', 'Repl'))
    // return availability
  }

  private sumItemAvailabilities = (item, type = 'oryginał') => {
    let amount = 0

    item.get('_amounts').forEach(i => {
      if (i.get('type') === type) {
        amount += i.get('amount')
      }
    })

    return parseFloat(amount.toFixed(2))
  }

  private setOpenPopover = productId => {
    // if (!openPopover) return null
    console.log({ productId })
    this.props.actions.showProductInfo(productId)
    this.props.actions.loadProductData(productId)
  }

  updateAmount = async (id, value) => {
    const val = value
      .replace(/[^0-9.,]/g, '')
      .replace(',', '.')
      .trim()
    await this.setState({
      amounts: this.state.amounts.set(id, val),
    })
  }

  addArticleToCart = async (item) => {
    const id = item.get('idArtykulu')
    if (!this.state.amounts.has(id)) {
      await this.updateAmount(id, '1')
    }

    this.props.actions.addArticleToCart(item, this.state.amounts.get(id))
  }

  isCrk = (wrapper = '') => {
    return (wrapper || this.props.wrapper) === SEARCH_WRAPPER_CRK
  }

  isStore = (wrapper = '') => {
    return (wrapper || this.props.wrapper) === SEARCH_WRAPPER_STORE
  }

  isMore = (id) => this.props.more.get(id + '')

  toggleMore = async (item) => {
    const id = item.get('idArtykulu')
    const status = this.isMore(id)

    await this.props.actions.setMore(id, !status);
  }

  showImagePopup = (url, current) => {
    sendIframeMsg('IMAGE_POPUP', { images: url, current: current})
  }

  handleMouseEnter = item => {
    let id = setTimeout(() => {
      this.props.actions.setMore(item.get('idArtykulu'), true);
    }, 3000)


    this.setState({
      hover: id,
    });
  }

  handleMouseLeave = () => {
    const {
      hover,
    } = this.state

    clearTimeout(hover)
  }

  renderCrkHeader() {
    return (
      <HeaderRow wrapper={SEARCH_WRAPPER_CRK}>
        <TableCell style={{ alignItems: 'flex-start', paddingLeft: 36 }}>
          <HeaderLabel>Oznaczenie</HeaderLabel>
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          <HeaderLabel>PRODUCENT</HeaderLabel>
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          <HeaderLabel>Opis</HeaderLabel>
        </TableCell>
        <TableCell/>
        <TableCell>
          <HeaderLabel>J.M.</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>Z mag.</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>2 dni</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>7 dni</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>30 dni</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>równoważ.</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>Do koszyka</HeaderLabel>
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start', paddingLeft: '25px' }}>
          <HeaderLabel>Info</HeaderLabel>
        </TableCell>
      </HeaderRow>
    )
  }

  renderStoreHeader() {
    const {
      availability,
    } = this.props

    const availList = Array.isArray(AVAILABILITY_LIST) ? AVAILABILITY_LIST : [];
    const availName = (availList.find(a => a.value === availability) || {name: ''}).name;

    return (
      <HeaderRow wrapper={SEARCH_WRAPPER_STORE}>
        <TableCell style={{ alignItems: 'flex-start', paddingLeft: 36 }}>
          <HeaderLabel>Oznaczenie</HeaderLabel>
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          <HeaderLabel>PRODUCENT</HeaderLabel>
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          <HeaderLabel>Opis</HeaderLabel>
        </TableCell>
        <TableCell/>
        <TableCell>
          <HeaderLabel>J.M.</HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>
            Dostępne
            <div>{ availability !== 'availOrigNow' ? availName : `${availName.replace(/^Dostępne/gi, '')}` }</div>
          </HeaderLabel>
        </TableCell>
        <TableCell>
          <HeaderLabel>DOSTĘPNE RÓWNOWAŻNIKI</HeaderLabel>
        </TableCell>
        <TableCell/>
      </HeaderRow>
    )
  }

  renderDimensions(item) {
    const w = item.get('wymiarW')
    const s = item.get('wymiarS')
    const g = item.get('wymiarG')

    const dimensions: Array<number> = []

    if (w) dimensions.push(w.toFixed(2))
    if (s) dimensions.push(s.toFixed(2))
    if (g) dimensions.push(g.toFixed(2))

    return dimensions.length ? <Dimensions>({dimensions.join(' x ')})</Dimensions> : null
  }

  renderCrkProductRow(item) {
    const {
      availability,
      popoverProduct,
    } = this.props

    const imagesString = item.get('rysunek')
    const images = imagesString && imagesString.split(';')
    const imagesObj = images && images.map(img => ({ url: getImageUrl(img)}))

    const hasAvail = item.get('availOrigNow') !== null
    const isPopoverOpen = popoverProduct && (popoverProduct.get('idArtykulu') === item.get('idArtykulu'))

    return (
      <TableRow wrapper={SEARCH_WRAPPER_CRK}
                key={item.get('idArtykulu')}
                className={isPopoverOpen ? 'infoShown' : ''}
                onMouseEnter={() => this.handleMouseEnter(item)}
                onMouseLeave={() => this.handleMouseLeave()}
                onClick={() => this.props.actions.showReplacementsTable(item, availability)}>
        <TableCell style={{ alignItems: 'flex-start', paddingLeft: 36 }}>
          {item.get('nazwaOryginalna')}
          <SecondaryInfo>{item.get('nazwa')}</SecondaryInfo>
          {process.env.NODE_ENV === 'development' ?
            <SecondaryInfo>{item.get('idArtykulu')}</SecondaryInfo> : null}
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>{item.get('pole1')}</TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>{item.get('elasticsearchOpis')} {item.get('info')}
          {this.renderDimensions(item) }
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          {(item.get('html') || imagesObj) && (
            <ToggleTextButton
              padding={8}
              label={this.isMore(item.get('idArtykulu')) ? 'Mniej' : 'Więcej'}
              isExpanded={this.isMore(item.get('idArtykulu'))}
              onClick={(e) => {
                e.stopPropagation()
                this.toggleMore(item)
              }}/>
          )}
        </TableCell>
        <TableCell>{item.get('jednostka')}</TableCell>
        <TableCell>
          {hasAvail ? item.get('availOrigNow', 0) : null}
          {process.env.NODE_ENV === 'development' ?
            <SecondaryInfo>{item.get('availReplNow', 0)}</SecondaryInfo> : null}
        </TableCell>
        <TableCell>
          {hasAvail ? item.get('availOrig2days', 0) - item.get('availOrigNow', 0) : null}
          {process.env.NODE_ENV === 'development' ?
            <SecondaryInfo>{item.get('availRepl2days', 'b/d')}</SecondaryInfo> : null}
        </TableCell>
        <TableCell>
          {hasAvail ? item.get('availOrig7days', 0) - item.get('availOrig2days', 0) : null}
          {process.env.NODE_ENV === 'development' ?
            <SecondaryInfo>{item.get('availRepl7days', 'b/d')}</SecondaryInfo> : null}
        </TableCell>
        <TableCell>
          {hasAvail ? item.get('availOrig30days', 0) - item.get('availOrig7days', 0) : null}
          {process.env.NODE_ENV === 'development' ?
            <SecondaryInfo>{item.get('availRepl30days', 'b/d')}</SecondaryInfo> : null}
        </TableCell>
        <TableCell>{this.renderItemAlternativesAvailability(item)}</TableCell>
        <TableCell style={{ alignItems: 'center', flexDirection: 'row' }}>
          <InlineInput
            onClick={(e) => {
              e.stopPropagation()
            }}
            onChange={e => this.updateAmount(item.get('idArtykulu'), e.target.value)}
            type="text"
            placeholder={item.get('jednostka', 'szt.')}
            value={this.state.amounts.get(item.get('idArtykulu'), '1')}
          />
          <Button
            withCircle
            icon={'+'}
            onClick={(e) => {
              e.stopPropagation()
              this.addArticleToCart(item)
            }}
          />
        </TableCell>
        <TableCell style={{
          alignItems: 'center',
          flexDirection: 'row',
          paddingRight: 36,
        }}>
          <Button withCircle icon={'▶'}/>
        </TableCell>
        <TableCellDesc style={{ paddingLeft: 36 }}>
          <Collapse isOpen={this.isMore(item.get('idArtykulu'))}>
            <div style={{display: 'flex'}} onClick={(event) => {event.stopPropagation()}}>
              <div className={'html-wrapper'} style={{width: '75%'}}>{ReactHtmlParser(item.get('html'))}</div>
              { imagesObj && (
                <ImageSliderWrapper style={{width: '25%'}}>
                  { this.isMore(item.get('idArtykulu')) && (
                    <SliderWrapper>
                      <SimpleImageSlider
                        width={'100%'}
                        height={'100%'}
                        images={imagesObj}
                        navStyle={2}
                        bgColor={'#fff'}
                        showBullets={false}
                        onClick={(idx, event) => {
                          event.stopPropagation();
                          this.showImagePopup(imagesObj, idx)
                        }}
                        />
                      </SliderWrapper>
                    )}
                </ImageSliderWrapper>
              )}
            </div>
          </Collapse>
        </TableCellDesc>
      </TableRow>
    )
  }

  renderStoreProductRow(item) {
    const {
      availability,
      popoverProduct,
      exactQuantities,
    } = this.props

    const imagesString = item.get('rysunek')
    const images = imagesString && imagesString.split(';')
    const imagesObj = images && images.map(img => ({ url: getImageUrl(img)}))

    const isPopoverOpen = popoverProduct && (popoverProduct.get('idArtykulu') === item.get('idArtykulu'))

    return (
      <TableRow wrapper={SEARCH_WRAPPER_STORE}
                key={item.get('idArtykulu')}
                className={isPopoverOpen ? 'infoShown' : ''}
                onMouseEnter={() => this.handleMouseEnter(item)}
                onMouseLeave={() => this.handleMouseLeave()}
                onClick={() => this.props.actions.showReplacementsTable(item, availability)}>
        <TableCell style={{ alignItems: 'flex-start', paddingLeft: 36 }}>
          {item.get('nazwaOryginalna')}
          {/* <SecondaryInfo>{item.get('nazwa')}</SecondaryInfo> */}
          {process.env.NODE_ENV === 'development' ?
            <SecondaryInfo>{item.get('idArtykulu')}</SecondaryInfo> : null}
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>{item.get('pole1')}</TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          {item.get('elasticsearchOpis')} {item.get('info')}
          {this.renderDimensions(item) }
        </TableCell>
        <TableCell style={{ alignItems: 'flex-start' }}>
          {(item.get('html') || imagesObj) && (
            <ToggleTextButton
              padding={8}
              label={this.isMore(item.get('idArtykulu')) ? 'Mniej' : 'Więcej'}
              isExpanded={this.isMore(item.get('idArtykulu'))}
              onClick={(e) => {
                e.stopPropagation()
                this.toggleMore(item)
              }}/>
          )}
        </TableCell>
        <TableCell>{item.get('jednostka')}</TableCell>
        <TableCell>{exactQuantities ? this.sumItemAvailabilities(item, 'oryginał'): toApproximateQuantities(this.sumItemAvailabilities(item, 'oryginał'))}</TableCell>
        <TableCell>{exactQuantities ? this.sumItemAvailabilities(item, 'RÓWNOWAŻNIK'): toApproximateQuantities(this.sumItemAvailabilities(item, 'RÓWNOWAŻNIK'))}</TableCell>
        <TableCell style={{
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          paddingRight: 36,
        }}>
          <Button label={'Wybierz'}/>
        </TableCell>
        <TableCellDesc style={{ paddingLeft: 36 }}>
          <Collapse isOpen={this.isMore(item.get('idArtykulu'))}>
            <div style={{display: 'flex'}} onClick={(event) => {event.stopPropagation()}}>
              <div className={'html-wrapper'} style={{width: '75%'}}>{ReactHtmlParser(item.get('html'))}</div>
              { imagesObj && (
                <ImageSliderWrapper style={{width: '25%'}}>
                  { this.isMore(item.get('idArtykulu')) && (
                    <SliderWrapper>
                      <SimpleImageSlider
                        width={'100%'}
                        height={'100%'}
                        images={imagesObj}
                        navStyle={2}
                        bgColor={'#fff'}
                        showBullets={false}
                        onClick={(idx, event) => {
                          event.stopPropagation();
                          this.showImagePopup(imagesObj, idx)
                        }}
                      />
                    </SliderWrapper>
                  )}
                </ImageSliderWrapper>
              )}
            </div>
          </Collapse>
        </TableCellDesc>
      </TableRow>
    )
  }

  render() {
    const {
      popoverProduct,
      items,
      error,
      total,
      dirty,
      loading,
      query,
      categoryTreeCode,
      categories,
      replacementsSearchMode,
      imageLabels,
      dimLabels,
      popoverItemType,
      producersCatalogs,
    } = this.props

    if (replacementsSearchMode) return null
    return (
      <div>
        <Wrapper>
          <Breadcrumbs onClickItem={this.onChangeCategory} treeCode={categoryTreeCode} categories={categories}/>
        </Wrapper>
        <Table>
          <TableBody>
            {this.isCrk() && this.renderCrkHeader()}
            {this.isStore() && this.renderStoreHeader()}
            <InfiniteScroll pageStart={0} loadMore={this.loadMore} hasMore={items.size < this.props.total.get('value')}
                            initialLoad={false}>
              {items.map((item) => (this.isCrk() && this.renderCrkProductRow(item)) || (this.isStore() && this.renderStoreProductRow(item)))}
            </InfiniteScroll>
          </TableBody>
        </Table>
        <Portal key="loader">
          <LoadingBearing src={bearingImg} className={loading ? 'loading' : ''}/>
        </Portal>
        {/* {error && <pre style={{ color: 'red' }}>{JSON.stringify(error, null, 2)}</pre>} */}
        { error && 
          <Message>
            <div>Jeśli nie znalazłeś wyników zawęź kryteria wyszukiwania</div>
          </Message>
        }
        { !error && dirty && items && items.size === 0 && (
          <Message>
            <div style={{margin: '0 0 16px'}}>
              Niestety nie znaleźliśmy żadnych wyników dla wyszukiwana {query.get('match')}
            </div>
            <div>Sprawdź czy wszystkie zwroty zostały wpisane poprawnie</div>
            <div>Spróbuj użyć spacji do odseparowania znanych członów nazwy, np. 6320 M C3 zamiast 6320MC3</div>
            <div>Spróbuj uogólnić zapytanie wprowadzając mniejszą ilość znaków</div>
            <div>Jeśli znasz wymiary spróbuj skorzystać z wyboru grupy produktów. W zależności od wybranej grupy wymiary mogą się różnić</div>
          </Message>
        ) }
        <div style={{ opacity: 0.5, textAlign: 'center', padding: 10, fontSize: 12 }}>
          {items.size || 0} z {total.get('relation', 'eq') !== 'eq' ? 'ponad' : ''} {total.get('value', 0)} artykułów
        </div>
        {popoverProduct && (
          <Portal key="productInfo">
            <ProductInfoPopover
              product={popoverProduct}
              imageLabels={imageLabels}
              dimLabels={dimLabels}
              categories={categories}
              productType={popoverItemType}
              producersCatalogs={producersCatalogs}
              onClose={() => this.props.actions.hideProductInfo()}
            />
          </Portal>
        )}
      </div>
    )
  }
}

// export default Button
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchResultsGrid)
