const KEY_TAB = 9
const KEY_ENTER = 13
const KEY_SPACE = 32
const KEY_COMMA = 188

export {
  KEY_TAB,
  KEY_ENTER,
  KEY_SPACE,
  KEY_COMMA,
}
