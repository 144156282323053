import { createReducer } from 'redux-act'
import * as a from 'src/actions/replacements'
import * as sf from 'src/actions/searchForm'
import { fromJS } from 'immutable'

export default createReducer(
  {
    [a.showReplacementsTable.toString()]: (state, { product }) => state.set('baseProduct', product),
    [a.hideReplacementsTable.toString()]: state => state.set('baseProduct', null).set('results', null),
    [sf.clearSearchParams.toString()]: state => state.set('baseProduct', null).set('results', null),
    [sf.clearCustomClientFieldsSearchParams.toString()]: state => state.set('baseProduct', null).set('results', null),
    [a.showReplacementProductInfo.toString()]: (state, { id, replId, type }) => state.set('popoverItemId', replId).set('popoverItemType', type),
    [a.hideReplacementProductInfo.toString()]: state => state.set('popoverItemId', null).set('popoverItemType', null),
    [a.showReplacementProductDetails.toString()]: (state, { id, replId }) => state.set('popoverItemId', replId),
    [a.hideReplacementProductDetails.toString()]: state => state.set('popoverItemId', null).set('popoverItemType', null),
    [a.replacementsApiRequest.toString()]: state => state.set('loading', true),
    [a.replacementsApiSuccess.toString()]: (state, { data }) => {
      let results = fromJS(data)
      // if (concatResults) {
      //   results = state.updateIn(['results', 'hits', 'hits'], arr => arr.concat(fromJS(data.hits.hits))).get('results')
      // }
      return state
        .set('results', results)
        .set('error', false)
        .set('loading', false)
    },
    [a.replacementsApiFailure.toString()]: (state, error) =>
      state
        // .set('results', fromJS([]))
        .set('error', error),
  },
  fromJS({
    loading: false,
    error: null,
    results: [],
    popoverItemId: null,
    popoverItemType: null,
  })
)
